import React, { useEffect, useState } from 'react';
import postApi from '../../api/postApi';
import { formatDistanceToNowStrict } from 'date-fns';
import { vi } from 'date-fns/locale';
import CommentBox from './CommentBox';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faUser } from '@fortawesome/free-solid-svg-icons';
import rankName from '../../utils/user/rankName';

function AnswerQuestion({ postId, fromColorBg, toColorBg }) {
    const [comments, setComments] = useState([]);
    const [commentLimit, setCommentLimit] = useState(10);
    const [newComment, setNewComment] = useState('');
    const [newReply, setReply] = useState('');
    const { isLogined, userInfo } = useSelector((state) => state.auth);
    const fetchComment = async (postId, limit) => {
        try {
            const { data } = await postApi.getAllComment(
                postId || 'shopee',
                limit
            );
            setComments(
                data.post[0].comments.map((comment) => ({
                    ...comment,
                    isOpenReply: false,
                }))
            );
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchComment(postId, commentLimit);
    }, [postId, commentLimit]);

    const onOpenReply = (commentId) => {
        setComments((prev) =>
            prev.map((el) => ({
                ...el,
                isOpenReply: el.commentId === commentId,
            }))
        );
    };

    const onPostComment = async () => {
        if (!newComment) return;
        try {
            await postApi.newComment(postId, newComment);
            await fetchComment(postId);
            setNewComment('');
        } catch (error) {
            console.log(error);
        }
    };

    const onNewReply = async (commentId) => {
        if (!newReply) return;
        try {
            await postApi.newReply(commentId, newReply);
            await fetchComment(postId);
        } catch (error) {
            console.log(error);
        }
    };

    const onDeleteComment = async (commentId) => {
        try {
            await postApi.deteleComment(commentId);
            await fetchComment(postId);
        } catch (error) {
            console.log(error);
        }
    };

    const onDeleteReply = async (replyId) => {
        try {
            await postApi.deteleReply(replyId);
            await fetchComment(postId);
        } catch (error) {
            console.log(error);
        }
    };

    const onLoadComment = () => {
        setCommentLimit((prev) => (prev += 10));
    };
    return (
        <div className="p-2 bg-white rounded-lg">
            <div className="flex gap-1 justify-start items-center">
                <div
                    className={`h-10 p-2 aspect-square bg-gradient-to-br flex justify-center items-center ${
                        fromColorBg || 'from-orange-500'
                    } ${toColorBg || 'to-orange-600'} rounded-full`}
                >
                    {/* <img
                        src="img/ui/activity_white.svg"
                        alt="policy"
                        className="h-full w-full object-cover"
                    /> */}
                    <FontAwesomeIcon icon={faMessage} className="text-white" />
                </div>
                <div className="flex flex-col text-sm text-gray-700">
                    <span className="font-bold">HỎI ĐÁP</span>
                </div>
            </div>
            {!isLogined && (
                <div className="flex flex-col gap-2 justify-center items-center p-3 bg-primary-300">
                    <span>Đăng nhập để bình luận</span>
                    <div>
                        <Link
                            className="p-2 bg-black text-white rounded-md"
                            to={'/login'}
                        >
                            Đăng nhập
                        </Link>
                    </div>
                </div>
            )}
            {isLogined && (
                <div className="p-2">
                    <CommentBox
                        value={newComment}
                        setValue={setNewComment}
                        onSubmit={onPostComment}
                        autoFocus={false}
                    />
                </div>
            )}
            {comments.length === 0 && (
                <div className="p-6 text-center text-gray-400 italic">
                    <p>Hãy là người bình luận đầu tiên</p>
                </div>
            )}
            <div className="flex flex-col gap-2 text-sm">
                {comments.map((comment) => (
                    <div
                        className="flex gap-2 p-2 w-full"
                        key={comment.commentId}
                    >
                        <div className="h-12 w-12 min-w-[48px] rounded-full overflow-hidden flex justify-center items-center">
                            {comment.user.avatar && (
                                <img
                                    className="h-full w-full object-cover"
                                    src={`${process.env.REACT_APP_API_PUBLIC_URL}/img/users/${comment.user.avatar}`}
                                    alt="avt"
                                />
                            )}
                            {!comment.user.avatar && (
                                <div className="w-10 h-10 rounded-full bg-slate-200 flex justify-center items-center text-xl">
                                    <FontAwesomeIcon icon={faUser} />
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col gap-2 rounded-lg justify-start w-full">
                            <div className="">
                                <div className="bg-slate-200 rounded-lg px-2 py-1">
                                    <div className="flex gap-1 items-center">
                                        <span>
                                            {comment.user?.name ||
                                                `user-${comment.user.userId}`}
                                        </span>
                                        <div className="text-xs">
                                            {comment.user?.role === 'admin'
                                                ? rankName(888)
                                                : rankName(comment.user.rank)}
                                        </div>
                                        <span className="text-xs">
                                            {formatDistanceToNowStrict(
                                                comment.createTime,
                                                {
                                                    locale: vi,
                                                }
                                            )}
                                        </span>
                                    </div>
                                    <p>{comment.comment}</p>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span></span>
                                    {isLogined && (
                                        <div className="flex gap-2 font-semibold">
                                            <span
                                                onClick={() =>
                                                    onOpenReply(
                                                        comment.commentId
                                                    )
                                                }
                                                className="hover:underline cursor-pointer"
                                            >
                                                Trả lời
                                            </span>
                                            {(userInfo.role === 'admin' ||
                                                comment.owner ===
                                                    userInfo.userId) && (
                                                <span
                                                    onClick={() =>
                                                        onDeleteComment(
                                                            comment.commentId
                                                        )
                                                    }
                                                    className="hover:underline cursor-pointer"
                                                >
                                                    Xoá
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 w-full border-l pl-2">
                                {comment.replies.map((reply) => (
                                    <div
                                        className="flex gap-2 w-full"
                                        key={reply.id}
                                    >
                                        <div className="h-12 w-12 min-w-[48px] rounded-full overflow-hidden flex justify-center items-center">
                                            {reply.user.avatar && (
                                                <img
                                                    className="h-full w-full object-cover"
                                                    src={`${process.env.REACT_APP_API_PUBLIC_URL}/img/users/${reply.user.avatar}`}
                                                    alt="avt"
                                                />
                                            )}
                                            {!reply.user.avatar && (
                                                <div className="w-10 h-10 rounded-full bg-slate-200 flex justify-center items-center text-xl">
                                                    <FontAwesomeIcon
                                                        icon={faUser}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="w-full">
                                            <div className="bg-slate-200 rounded-lg px-2 py-1">
                                                <div className="flex gap-1 items-center">
                                                    <span>
                                                        <span>
                                                            {reply.user?.name ||
                                                                `user-${reply.user.userId}`}
                                                        </span>
                                                    </span>
                                                    <div className="text-xs">
                                                        {reply.user?.role ===
                                                        'admin'
                                                            ? rankName(888)
                                                            : rankName(
                                                                  reply.user
                                                                      .rank
                                                              )}
                                                    </div>
                                                    <span className="text-xs">
                                                        {formatDistanceToNowStrict(
                                                            reply.createTime,
                                                            {
                                                                locale: vi,
                                                            }
                                                        )}
                                                    </span>
                                                </div>
                                                <p>{reply.comment}</p>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <span className="text-xs"></span>
                                                {isLogined && (
                                                    <div className="flex gap-2 font-semibold">
                                                        <span
                                                            onClick={() =>
                                                                onOpenReply(
                                                                    comment.commentId
                                                                )
                                                            }
                                                            className="hover:underline cursor-pointer"
                                                        >
                                                            Trả lời
                                                        </span>
                                                        {(userInfo.role ===
                                                            'admin' ||
                                                            reply.owner ===
                                                                userInfo.userId) && (
                                                            <span
                                                                onClick={() =>
                                                                    onDeleteReply(
                                                                        reply.id
                                                                    )
                                                                }
                                                                className="hover:underline cursor-pointer"
                                                            >
                                                                Xoá
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {comment.isOpenReply && (
                                <CommentBox
                                    value={newReply}
                                    setValue={setReply}
                                    onSubmit={() =>
                                        onNewReply(comment.commentId)
                                    }
                                    autoFocus={true}
                                />
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <div className="p-2">
                <div
                    className="flex justify-center items-center bg-blue-500 p-3 text-white font-bold text-sm cursor-pointer"
                    onClick={onLoadComment}
                >
                    <span>Tải thêm 10 bình luận</span>
                </div>
            </div>
        </div>
    );
}

export default AnswerQuestion;
