import axiosClient from './axiosClient';

const shopeexuApi = {
    getAllSpinner(time) {
        const baseURL = `shopeexu/all_spinner?limit=20&startTime[gte]=${time}`;
        return axiosClient.get(baseURL);
    },
};

export default shopeexuApi;
