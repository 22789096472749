import {
    faCheck,
    faChevronLeft,
    faMoneyBillTransfer,
    faNoteSticky,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import authApi from '../../api/authApi';
import { authActions } from '../../store/authSlice';

function Notification() {
    const { userInfo } = useSelector((state) => state.auth);
    const notifications = userInfo?.notifications;
    const dispatch = useDispatch();
    const onReadAll = async () => {
        try {
            await authApi.readAllNoti();
            const { data } = await authApi.getUserInfo();
            dispatch(authActions.setUserInfo(data.data.user));
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className="h-dvh flex flex-col justify-between">
            <div>
                <div className="relative p-3 flex justify-center items-center border-b shadow-sm">
                    <h2 className="text-center font-semibold">Thông báo</h2>
                    <Link
                        className="absolute left-2 p-2 hover:bg-slate-200 rounded-lg"
                        to={'/'}
                    >
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            className="pointer-events-none"
                        />
                    </Link>
                </div>
                <div className="text-center text-sm py-2 bg-gray-200 relative">
                    <p> Thông báo sẽ hết hạn sau 30 ngày</p>
                </div>
            </div>
            {notifications && notifications?.length > 0 && (
                <div className={`p-2 flex flex-1 flex-col gap-1 overflow-auto`}>
                    {notifications.map((noti, index) => (
                        <div
                            key={index}
                            className={`flex gap-2 justify-start border-b last:border-none py-2 hover:bg-slate-200 ${
                                noti.unread === false && 'opacity-50'
                            }`}
                        >
                            <div className="text-3xl p-4">
                                <FontAwesomeIcon icon={faMoneyBillTransfer} />
                            </div>
                            <div className="text-sm flex flex-col">
                                <p className="font-bold">
                                    {noti.title || 'Thông báo'}
                                </p>
                                <span>{noti.message}</span>
                                <span className="text-sm text-gray-500">
                                    {`Thông báo ngày: ${format(
                                        new Date(noti.createTime),
                                        'dd/MM/yyyy'
                                    )}`}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {!notifications?.length && (
                <div className="flex-1 flex items-center p-4 flex-col text-gray-500">
                    <FontAwesomeIcon
                        icon={faNoteSticky}
                        className="text-4xl text-gray-400"
                    />
                    <span>Không có thông báo mới</span>
                </div>
            )}
            {notifications?.some((el) => el.unread) && (
                <div
                    onClick={onReadAll}
                    className="flex justify-center items-center gap-1 py-3 bg-primary-200 cursor-pointer"
                >
                    <span>Đánh dấu đã đọc tất cả</span>
                    <FontAwesomeIcon icon={faCheck} />
                </div>
            )}
        </div>
    );
}

export default Notification;
