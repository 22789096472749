import React from 'react';
import Header from '../../../components/Header';

function ShopeeLiveGuide() {
    return (
        <div className="p-2 bg-white h-dvh">
            <Header />
            <div className="bg-white pt-16">
                <h4 className="text-center">
                    <span style={{ color: '#000000' }}>
                        <strong>
                            <span style={{ fontSize: '12pt' }}>
                                <span style={{ backgroundColor: 'white' }}>
                                    <span
                                        style={{
                                            fontFamily:
                                                '"Times New Roman",serif',
                                        }}
                                    >
                                        <span style={{ fontSize: '11.5pt' }}>
                                            <span
                                                style={{
                                                    fontFamily:
                                                        '"Segoe UI Emoji",sans-serif',
                                                }}
                                            >
                                                🔥
                                            </span>
                                        </span>
                                        <span style={{ fontSize: '11.5pt' }}>
                                            <span
                                                style={{
                                                    fontFamily:
                                                        '"Segoe UI Historic",sans-serif',
                                                }}
                                            >
                                                H
                                            </span>
                                        </span>
                                        <span style={{ fontSize: '11.5pt' }}>
                                            <span
                                                style={{
                                                    fontFamily:
                                                        '"Calibri",sans-serif',
                                                }}
                                            >
                                                Ư
                                            </span>
                                        </span>
                                        <span style={{ fontSize: '11.5pt' }}>
                                            <span
                                                style={{
                                                    fontFamily:
                                                        '"Calibri",sans-serif',
                                                }}
                                            >
                                                ỚNG DẪN MUA HÀNG SHOPEE <br />{' '}
                                                ÁP ĐƯỢC MÃ LIVE
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </strong>
                    </span>
                </h4>
                <h4>&nbsp;</h4>
                <p>
                    <span style={{ fontSize: '11pt' }}>
                        <span style={{ backgroundColor: 'white' }}>
                            <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                                <span style={{ fontSize: '0.9375rem' }}>
                                    <span
                                        style={{ color: 'var(--primary-text)' }}
                                    >
                                        <span style={{ fontSize: '0.9375rem' }}>
                                            <span
                                                style={{
                                                    color: 'var(--primary-text)',
                                                }}
                                            >
                                                <strong>
                                                    <span
                                                        style={{
                                                            fontSize: '11.5pt',
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily:
                                                                    '"inherit",serif',
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: '#050505',
                                                                }}
                                                            >
                                                                Bước 1:
                                                            </span>
                                                        </span>
                                                    </span>
                                                </strong>
                                                <span
                                                    style={{
                                                        fontSize: '11.5pt',
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontFamily:
                                                                '"inherit",serif',
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                color: '#050505',
                                                            }}
                                                        >
                                                            &nbsp;Copy link sản
                                                            phẩm và gửi vào web{' '}
                                                            <a
                                                                href="https://longhousee.live"
                                                                style={{
                                                                    color: '#0563c1',
                                                                    textDecoration:
                                                                        'underline',
                                                                    fontFamily:
                                                                        '"inherit",serif',
                                                                }}
                                                            >
                                                                https://longhousee.live
                                                            </a>{' '}
                                                            (hoặc tại page:{' '}
                                                        </span>
                                                    </span>
                                                </span>
                                                <span
                                                    style={{ color: 'black' }}
                                                >
                                                    <a
                                                        href="https://longhousee.me/CYlj"
                                                        style={{
                                                            color: '#0563c1',
                                                            textDecoration:
                                                                'underline',
                                                            fontFamily:
                                                                '"Times New Roman",serif',
                                                        }}
                                                    >
                                                        https://www.facebook.com/longhouseedotcom
                                                    </a>
                                                    )
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>
                <p>&nbsp;</p>
                <p>
                    <span style={{ fontSize: '11pt' }}>
                        <span style={{ backgroundColor: 'white' }}>
                            <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                                <span style={{ fontSize: '0.9375rem' }}>
                                    <span style={{ fontSize: '0.9375rem' }}>
                                        <strong>
                                            <span
                                                style={{ fontSize: '11.5pt' }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily:
                                                            '"inherit",serif',
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            color: '#050505',
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '0.9375rem',
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: 'var(--primary-text)',
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '0.9375rem',
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            color: 'var(--primary-text)',
                                                                        }}
                                                                    >
                                                                        Bước 2:
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </strong>
                                        <span style={{ fontSize: '11.5pt' }}>
                                            <span
                                                style={{
                                                    fontFamily:
                                                        '"inherit",serif',
                                                }}
                                            >
                                                <span
                                                    style={{ color: '#050505' }}
                                                >
                                                    &nbsp;Sau khi dán link xong
                                                    sẽ hiện giờ live dự kiến bên
                                                    dưới. Việc của bạn là hẹn
                                                    giờ để tới phiên live vào
                                                    giỏ hàng mua sản phẩm
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>
                <p>&nbsp;</p>
                <p>
                    <span style={{ fontSize: '11pt' }}>
                        <span style={{ backgroundColor: 'white' }}>
                            <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                                <span style={{ fontSize: '0.9375rem' }}>
                                    <span style={{ fontSize: '0.9375rem' }}>
                                        <span style={{ fontSize: '0.9375rem' }}>
                                            <span
                                                style={{
                                                    color: 'var(--primary-text)',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: '0.9375rem',
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            color: 'var(--primary-text)',
                                                        }}
                                                    >
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '11.5pt',
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontFamily:
                                                                            '"inherit",serif',
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            color: '#050505',
                                                                        }}
                                                                    >
                                                                        Bước 3:
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </strong>
                                                        &nbsp;
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '11.5pt',
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        '"Segoe UI Emoji",sans-serif',
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: '#050505',
                                                                    }}
                                                                >
                                                                    ⚠
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '11.5pt',
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        '"Segoe UI Emoji",sans-serif',
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: '#050505',
                                                                    }}
                                                                >
                                                                    ️
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '11.5pt',
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        '"inherit",serif',
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: '#050505',
                                                                    }}
                                                                >
                                                                    Mỗi phiên
                                                                    live chỉ
                                                                    diễn ra
                                                                    trong vòng
                                                                    10 phút. Tag
                                                                    Shopee Live
                                                                    là tag vĩnh
                                                                    viễn. Nên
                                                                    giả sử 0H có
                                                                    mã live ngon
                                                                    thì bạn đặt
                                                                    lịch live
                                                                    sớm từ trước
                                                                    đó phiên
                                                                    live 18H,
                                                                    19H… rùi vào
                                                                    giỏ thêm từ
                                                                    giỏ live vào
                                                                    giỏ hàng để
                                                                    sản phẩm có
                                                                    tag Shopee
                                                                    Live là được
                                                                    nha
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>
                <p>&nbsp;</p>
            </div>
            {/* <div>
                <h4>🔥HƯỚNG DẪN MUA HÀNG SHOPEE ÁP ĐƯỢC MÃ LIVE</h4>
                <div>
                    <div>
                        <span>Bước 1</span>
                        <p>
                            Copy link sản phẩm và gửi vào web{' '}
                            <Link to={'https://longhousee.live'}>
                                https://longhousee.live
                            </Link>{' '}
                            (hoặc tại page:{' '}
                            <Link
                                to={'https://www.facebook.com/longhouseedotcom'}
                            >
                                https://www.facebook.com/longhouseedotcom
                            </Link>
                            )
                        </p>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default ShopeeLiveGuide;
