import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

function LiveInfo({ goliveSession, pendingLives }) {
    const ZALO_LIVE_GROUP = 'https://zalo.me/g/uxtvkq172';
    const SHOP_LIVE_URL = 'https://shope.ee/8pLLOATVs6';
    return (
        <div className="p-2 bg-white rounded-lg shadow-lg">
            <p className="font-bold">Thông tin phiên LIVE</p>
            <div className="flex gap-4">
                <span>Trạng thái:</span>
                <span
                    className={`font-bold flex items-center gap-2 ${
                        goliveSession.length > 0
                            ? 'text-red-500'
                            : 'text-gray-500'
                    }`}
                >
                    <span>{goliveSession.length > 0 ? 'LIVE' : 'OFF'}</span>
                    {goliveSession.length > 0 && (
                        <span className="relative flex h-3 w-3">
                            <span
                                className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 bg-red-400`}
                            ></span>
                            <span
                                className={`relative inline-flex rounded-full h-3 w-3 bg-red-500`}
                            ></span>
                        </span>
                    )}
                </span>
            </div>
            {goliveSession.length === 0 && pendingLives.length > 0 && (
                <div className="flex gap-4">
                    <span>Phiên LIVE kế tiếp:</span>
                    <span className="font-semibold">
                        {pendingLives[0].title}
                    </span>
                </div>
            )}
            {goliveSession.length > 0 && (
                <div className="flex gap-4">
                    <span>Giỏ Live:</span>
                    <div>
                        <Link
                            className="text-orange-600 flex gap-2 items-center"
                            to={goliveSession[0].liveUrl || SHOP_LIVE_URL}
                        >
                            <FontAwesomeIcon icon={faShoppingCart} />
                            <span className="underline font-bold">
                                ĐI ĐẾN GIỎ LIVE
                            </span>
                        </Link>
                    </div>
                </div>
            )}
            <div className="py-2">
                <p>Tham gia nhóm Zalo để cập nhật lịch Live nhé</p>
                <span className="flex gap-4 items-center">
                    <Link
                        className="underline text-primary-600"
                        to={ZALO_LIVE_GROUP}
                    >
                        {ZALO_LIVE_GROUP}
                    </Link>
                </span>
            </div>
        </div>
    );
}

export default LiveInfo;
