import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.bubble.css';
import postApi from '../../api/postApi';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import AnswerQuestion from '../../components/Q&A/Q&A';

function PostPage() {
    const { postid } = useParams();
    const [post, setPost] = useState();
    const { userInfo } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const deltePost = async () => {
        if (!postid) return;
        try {
            await postApi.deletePost(postid);
            navigate('/post');
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const { data } = await postApi.getGuidePost(
                    `category=guide&postId=${postid}`
                );
                setPost(data.post[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchPost();
    }, [postid]);
    return (
        <div>
            <Header />
            {post && (
                <div className="flex flex-col justify-between h-full md:w-[768px] lg:w-[1024px] md:mx-auto pt-16 gap-2">
                    {userInfo?.role === 'admin' && (
                        <div className="p-2 flex gap-2 text-xl justify-end">
                            <FontAwesomeIcon
                                className="p-2 text-slate-400 hover:text-black cursor-pointer"
                                icon={faEdit}
                                onClick={() =>
                                    navigate('/post/post_editor/' + postid)
                                }
                            />
                            <FontAwesomeIcon
                                className="p-2 text-slate-400 hover:text-black cursor-pointer"
                                icon={faTrash}
                                onClick={deltePost}
                            />
                        </div>
                    )}
                    <h3 className="p-4 font-bold">{post?.title}</h3>
                    <div className="rounded-md overflow-hidden w-full p-4">
                        <img
                            src={`${process.env.REACT_APP_API_PUBLIC_URL}/img/post-thumbs/${post.imagePath}`}
                            className="h-full w-full object-cover"
                            alt="banner"
                        />
                    </div>
                    <div className="p-4">
                        <ReactQuill
                            value={post.content}
                            readOnly={true}
                            theme="bubble"
                        />
                    </div>
                    <div className="flex gap-1 justify-end text-sm italic text-gray-800 p-4">
                        <span>Ngày tạo:</span>
                        <span>
                            {format(post.createTime, 'dd/MM/yyy HH:mm')}
                        </span>
                    </div>
                </div>
            )}
            <AnswerQuestion postId={postid} />
        </div>
    );
}

export default PostPage;
