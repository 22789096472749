import React, { useEffect } from 'react';
import Header from '../../components/Header';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { refActions } from '../../store/refSlice';
import { popupActions } from '../../store/popupSlice';
import UserHome from './UserHome';
import MainCashBack from './MainCashBack';
import Favorites from './Favorites';
import Social from './Social';
import AboutUs from './AboutUs';
import FooterV2 from '../../components/FooterV2';
import PostSlider from './PostSlider';
import Modal from '../../components/Modal';
import GoToGuide from '../../components/Modal/popup/GoToGuide';

function Home() {
    const { guidePopup } = useSelector((state) => state.popup);
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const randomNum = Math.floor(Math.random() * 10);
    if (randomNum === 5) {
        dispatch(popupActions.setGuide(true));
    }

    useEffect(() => {
        if (!params.get('ref')) return;
        document.cookie = `ref=${params.get('ref')}`;
        dispatch(refActions.setRef(params.get('ref')));
    }, [params, dispatch]);
    return (
        <div className="h-dvh flex flex-col">
            <Header />
            <div className="flex flex-col justify-between h-full md:w-[768px] lg:w-[1024px] md:mx-auto ">
                <div className="pt-16 flex flex-col gap-2">
                    <UserHome />
                    <MainCashBack />
                    <Favorites />
                    <Social />
                    <Link
                        to={'https://longhousee-shopeexu.vercel.app/'}
                        target="_blank"
                        className="p-2 bg-white"
                    >
                        <div className="bg-[#d12515] p-1 rounded-lg">
                            <img
                                src="img/ui/shopee_spinner.png"
                                alt="spinner"
                                className="w-full"
                            />
                        </div>
                    </Link>
                    <PostSlider />
                    <AboutUs />
                    <div className="pb-20"></div>
                    <FooterV2 />
                </div>
            </div>
            <Modal isOpen={guidePopup}>
                <GoToGuide />
            </Modal>
        </div>
    );
}

export default Home;
