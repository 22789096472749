import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import shopeexuApi from '../../../api/shopeexuApi';
import { format, formatDistanceStrict } from 'date-fns';
import { vi } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronRight,
    faClock,
    faCoins,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import JoinZalo from '../../../components/Chatbox/JoinZalo';
import linkShopeeAffGenerator from '../../../utils/generateLinkAffShopee';

const clickAffLink = (url) => {
    window.location.href = url;
};

const affIdList = [
    { sub_id: 'longhousee', aff_id: 17353950011 },
    // { sub_id: 'vietnguyen', aff_id: 17343420119 },
];

function ShopeeXu() {
    const [spinnerList, setSpinnerList] = useState([]);
    const [realTime, setRealTime] = useState(Date.now());
    const [onGetLink] = useState(false);
    const fetchSpinner = async () => {
        try {
            const time = Date.now() + 10000;
            const { data } = await shopeexuApi.getAllSpinner(time);
            setSpinnerList(data.data.allSpinner);
        } catch (error) {
            console.log(error);
        }
    };

    window.location.href = 'https://longhousee-shopeexu.vercel.app/';

    useEffect(() => {
        fetchSpinner();
    }, []);
    useEffect(() => {
        const counter = setInterval(() => {
            const now = Date.now();
            setRealTime(now);
            setSpinnerList((prev) =>
                prev.filter((el) => new Date(el.startTime) > now)
            );
        }, 1000);

        return () => {
            clearInterval(counter);
        };
    });

    const onGoToHost = async (link) => {
        const newAffLink = linkShopeeAffGenerator(link, affIdList);

        // setOnGetLink(true);
        // try {
        //     const { data } = await shopeApi.getLinkAffVideo(link);
        //     const linkAff = data.linkAff.batchCustomLink[0].longLink;
        //     if (linkAff) {
        //         setOnGetLink(false);
        //         clickAffLink(linkAff);
        //     }
        // } catch (error) {
        //     console.log(error);
        // }
        // setOnGetLink(false);
        clickAffLink(newAffLink);
    };
    return (
        <div className="h-dvh flex flex-col">
            <Header />
            <div className="mt-16 p-2 flex flex-col gap-6 h-full md:w-[768px] lg:w-[1024px]  md:mx-auto">
                <div>
                    <img
                        className="w-full object-cover"
                        src="/img/banner/vongquayshopee.jpeg"
                        alt="vongquayshopee"
                    />
                </div>
                <div className="w-full flex flex-col gap-2">
                    {spinnerList.map((el, index) => (
                        <div
                            key={el.drawId}
                            className="bg-white rounded-md p-1 shadow-lg border"
                        >
                            <div className="flex w-full justify-between items-center border-b text-sm">
                                <span>{`#${index + 1}`}</span>
                                <div className="flex flex-col justify-center items-center">
                                    <span className="text-xl font-bold text-amber-400 flex items-center gap-1 shadow-lg border px-2 rounded-lg">
                                        {parseFloat(
                                            el.maxcoin
                                        ).toLocaleString()}
                                        <FontAwesomeIcon icon={faCoins} />
                                    </span>
                                    <span>{`SL: ${el.slot}`}</span>
                                </div>
                                <button
                                    disabled={onGetLink}
                                    onClick={() =>
                                        onGoToHost(
                                            `https://shopee.vn/${el.userName}`
                                        )
                                    }
                                    className=" bg-primary-500 text-white rounded-md h-full w-20 p-1 disabled:opacity-60 disabled:cursor-not-allowed"
                                >
                                    {onGetLink ? (
                                        <FontAwesomeIcon
                                            icon={faSpinner}
                                            className="animate-spin"
                                        />
                                    ) : (
                                        <span>
                                            Zô ngay{' '}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                            />
                                        </span>
                                    )}
                                </button>
                            </div>
                            <div className="text-sm flex justify-end items-center gap-2">
                                <span>{`Bắt đầu lúc: ${format(
                                    new Date(el.startTime),
                                    'HH:mm:ss'
                                )}`}</span>
                                <div className="flex gap-1 items-center">
                                    <FontAwesomeIcon icon={faClock} />
                                    <span className="font-semibold">
                                        {formatDistanceStrict(
                                            new Date(el.startTime),
                                            realTime,
                                            {
                                                locale: vi,
                                            }
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <JoinZalo />
                <div className="pb-12"></div>
            </div>
        </div>
    );
}

export default ShopeeXu;
