import axiosClient from './axiosClient';

const postApi = {
    getAllComment(postId, limit) {
        const baseUrl = `post/get_post?postId=${postId}&limit=${limit}`;
        return axiosClient.get(baseUrl);
    },
    getGuidePost(queryString) {
        const baseUrl = `post/get_post?${queryString}`;
        return axiosClient.get(baseUrl);
    },
    newPost(formData) {
        const baseUrl = `post/new_post`;
        return axiosClient.post(baseUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    updatePost(formData) {
        const baseUrl = `post/update_post`;
        return axiosClient.post(baseUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    deletePost(postId) {
        const baseUrl = `post/delete_post`;
        return axiosClient.post(baseUrl, {
            postId,
        });
    },
    newComment(postId, comment) {
        const baseUrl = 'post/new_comment';
        return axiosClient.post(baseUrl, {
            postId,
            comment,
        });
    },
    newReply(commentId, comment) {
        const baseUrl = 'post/new_reply';
        return axiosClient.post(baseUrl, {
            commentId,
            comment,
        });
    },
    deteleComment(commentId) {
        const baseUrl = 'post/delete_comment';
        return axiosClient.post(baseUrl, {
            commentId,
        });
    },
    deteleReply(replyId) {
        const baseUrl = 'post/delete_reply';
        return axiosClient.post(baseUrl, {
            replyId,
        });
    },
    getVideoList(query) {
        const baseUrl = `post/get_video_list?${query || ''}`;
        return axiosClient.get(baseUrl);
    },
    getVideoCategories(query) {
        const baseUrl = `post/get_video_categories`;
        return axiosClient.get(baseUrl);
    },
    deleteVideoList(videoId) {
        const baseUrl = `post/delete_video_list`;
        return axiosClient.post(baseUrl, { videoId });
    },
    deleteVideoCategory(categoryId) {
        const baseUrl = `post/delete_video_categories`;
        return axiosClient.post(baseUrl, { categoryId });
    },
};

export default postApi;
