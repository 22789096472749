import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import SearchInputV2 from '../../../components/Ecommerce/SearchInputV2';
import SearchResultV2 from '../../../components/Ecommerce/SearchResultV2';
import FooterV2 from '../../../components/FooterV2';
import CashBackGuide from '../../../components/Ecommerce/CashBackGuide';
import LongHouseePet from '../../../components/Ecommerce/LongHouseePet';
import AnswerQuestion from '../../../components/Q&A/Q&A';
import lazadaApi from '../../../api/lazadaApi';
import PolicyLazada from '../../../components/Ecommerce/PolicyLazada';

function LazadaNew() {
    const [searchTerm, setSearchTerm] = useState('');
    const [productList, setProductList] = useState([]);
    const [isLoading, setisLoading] = useState(false);

    useEffect(() => {
        if (!searchTerm) return;
        setProductList([]);
        const fetchProductInfoByLink = async () => {
            setisLoading(true);
            try {
                const { data } = await lazadaApi.getProductInfo(searchTerm);
                setProductList([data.productInfo]);
            } catch (error) {
                console.log(error);
                setProductList([]);
            }
            setisLoading(false);
        };
        fetchProductInfoByLink();
    }, [searchTerm]);

    return (
        <div className="h-dvh flex flex-col justify-between">
            <Header />
            <div className="pt-16 w-full md:w-[768px] lg:w-[1024px] mx-auto flex flex-col gap-2">
                <div>
                    <SearchInputV2
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        isLoading={isLoading}
                        platform={'lazada'}
                        cashBackTitle={'Hoàn tiền đến 22.36%'}
                        titleTextColor={'text-blue-800'}
                        borderInputColor={'border-blue-800'}
                    />
                </div>
                {!searchTerm && <LongHouseePet platform={'Lazada'} />}
                {searchTerm && (
                    <SearchResultV2
                        isLoading={isLoading}
                        platform={'lazada'}
                        productList={productList}
                        fromColorBg={'from-blue-600'}
                        toColorBg={'to-blue-800'}
                        buyBtnBgColor={'bg-blue-800'}
                        buyBtnTextColor={'text-blue-800'}
                        cashBackTextColor={'text-white'}
                    />
                )}

                <>
                    <div className="p-2 bg-white ">
                        <CashBackGuide
                            platform={'lazada'}
                            fromColorBg={'from-blue-600'}
                            toColorBg={'to-blue-800'}
                            borderColor={'border-blue-800'}
                        />
                    </div>
                    <PolicyLazada
                        fromColorBg={'from-blue-600'}
                        toColorBg={'to-blue-800'}
                    />
                </>

                <AnswerQuestion
                    postId={11}
                    fromColorBg={'from-blue-600'}
                    toColorBg={'to-blue-800'}
                />
            </div>
            <FooterV2 />
        </div>
    );
}

export default LazadaNew;
