import axiosClient from './axiosClient';

const bankApi = {
    getBankInfo: () => {
        const baseURL = 'bank';
        return axiosClient.get(baseURL);
    },
};

export default bankApi;
