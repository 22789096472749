import React from 'react';
import Header from '../../../components/Header';

function RejectOrderPost() {
    return (
        <div className="flex flex-col">
            <Header />
            <div className="mt-16 p-4 flex flex-col gap-4 md:w-[768px] lg:w-[1024px]  md:mx-auto text-sm ">
                <h2 className="font-bold text-base text-center">
                    TÌM HIỂU NGUYÊN NHÂN ĐƠN HÀNG BỊ HỦY,
                    <br />
                    HOÀN TIỀN 0Đ
                </h2>
                <p className="font-semibold text-justify">
                    &emsp;&ensp; Đội ngũ team Longhousee.com chúng mình cũng
                    tiếc về vấn đề này. Bởi lẽ, việc hoàn tiền dựa trên số tiền
                    mà sàn và các brand trả thưởng cho team, sau đó team mới có
                    số liệu thống kê lại là trả thưởng hoa hồng/hoàn tiền cho
                    các bạn. Điều này đồng nghĩa với việc hoa hồng bị hủy thì
                    công sức của các bạn và team Longhousee đều không được sàn
                    ghi nhận.
                </p>
                <p className="italic bg-primary-50 text-primary-600">
                    Lưu ý rằng: Việc duyệt đơn, hủy đơn và ghi nhận đơn hàng
                    quyền quyết hoàn toàn định thuộc về Lazada. Website
                    Longhousee.com chỉ lấy dữ liệu mà Lazada trả hàng hàng ngày
                    để đối soát đơn hàng của user nên sẽ không thể có sai sót
                    thông tin giữa web và sàn.
                </p>
                <h3 className="font-semibold">Đơn hàng bị hủy:</h3>
                <div>
                    <p>
                        &emsp;&ensp;Thực tế, sẽ không ai có thể biết rõ lý do cụ
                        thể nguyên nhân là gì, bởi lẽ việc này thuộc thông tin
                        bảo mật của sàn nên đội ngũ team Longhousee.com chỉ có
                        thể đưa ra 1 vài nguyên nhân chung mà phần lớn bị hủy
                        đơn như sau:
                    </p>
                    <ul className="list-inside list-disc p-4">
                        <li>Đăng nhập nhiều tài khoản trên cùng 1 thiết bị </li>
                        <li>
                            Sử dụng nhiều thiết bị hoặc tài khoản trong cùng một
                            địa chỉ mạng để đặt hàng trong thời gian cố định
                        </li>
                        <li>
                            Sử dụng nhiều thiết bị hoặc tài khoản trong cùng một
                            địa chỉ nhận hàng để đặt hàng trong thời gian cố
                            định
                        </li>
                        <li>
                            Sử dụng nhiều thiết bị để đặt cùng một loại sản phẩm
                            hoặc sử dụng 1 thiết bị đặt một loại sản phẩm nhiều
                            lần trong thời gian cố định
                        </li>
                        <li>
                            Sử dụng nhiều thiết bị để dùng cùng 1 loại mã giảm
                            giá hoặc freeship
                        </li>
                        <li>Sử dụng quá nhiều mã giảm giá cho một đơn hàng </li>
                        <li>
                            Sử dụng quá nhiều mã giảm giá – code đặc biệt từ KOL
                            để đặt hàng trong thời gian nhất định
                        </li>
                        <li>
                            Thời gian ở lại app Lazada quá ngắn (thông thường từ
                            khi mở app tới khi chốt đơn đặt hàng dưới 30 giây
                            thì gọi là ngắn)
                        </li>
                        <li>
                            Click chồng chéo link từ các group khác nhau, từ các
                            KOL khác nhau
                        </li>
                        <li>
                            Trong cùng thời gian nhất định, mở quá nhiều link
                            truy cập vào Lazada
                        </li>
                    </ul>
                </div>
                <section>
                    <h3 className="font-semibold">
                        Đơn hàng được duyệt nhưng hoàn tiền 0Đ hoặc hoàn tiền số
                        không đúng
                    </h3>
                    <p>
                        &emsp;&ensp;Dưới đây là danh sách các ngành hàng không
                        được Lazada trả hoa hồng:
                    </p>
                    <img
                        src="/img/lazadanocommission.png"
                        alt="lazadapic"
                        className="w-full h-auto"
                    />
                </section>
                <section className="p-2 flex flex-col gap-2">
                    <p>
                        <span className="font-semibold">Giải thích:</span> Hoa
                        hồng có 2 loại chính là{' '}
                        <span className="font-semibold text-primary-600">
                            hoa hồng cơ bản
                        </span>{' '}
                        được trả từ Lazada và{' '}
                        <span className="font-semibold text-primary-600">
                            hoa hồng thưởng
                        </span>{' '}
                        được trả từ brand
                    </p>
                    <p>
                        Danh sách phía trên bao gồm các nhóm hàng, ngành hàng
                        <span className="font-semibold text-primary-600">
                            không được trả hoa hồng cơ bản
                        </span>{' '}
                        từ Lazada{' '}
                        <span className="font-semibold text-primary-600">
                            nhưng vẫn được trả hoa hồng thưởng
                        </span>{' '}
                        từ các Brand. Thế nên, việc user/khách search link trên
                        website và báo có hoàn tiền là đúng, vì một số Brand vẫn
                        bật hoa hồng thưởng để trả cho team Longhousee.
                    </p>
                    <p>
                        Tuy nhiên,
                        <br />
                        Sau khi ghi nhận nhiều đơn hàng hoàn tiền 0Đ và có phản
                        hồi lại với team Lazada thì chúng mình nhận được phản
                        hồi là do việc bật/tắt{' '}
                        <span className="font-semibold text-primary-600">
                            hoa hồng thưởng
                        </span>{' '}
                        của brand Lazada không thể cập nhật thông tin chính xác
                        theo realtime (thời gian thực) được. Cụ thể là, nếu
                        brand điều chỉnh{' '}
                        <span className="font-semibold text-primary-600">
                            hoa hồng thưởng
                        </span>{' '}
                        ngày D thì ngày D+1 Lazada mới trả về kết quả chính xác.
                        Hoặc nói cách khác, dữ liệu hoàn tiền tại website khi
                        khách search link chỉ đúng khoảng 80%. Còn lại 20% có
                        thể sai sót do vấn đề update hoa hồng của Lazada quá
                        chậm.
                    </p>
                    <p>
                        Một lần nữa, rất mong khách hàng/user hiểu và thông cảm
                        về những trường hợp này giúp chúng mình. Chúng mình sẽ
                        cố gắng khắc phục, sửa đổi và phát triển từng ngày để
                        kịp thời mang lại trải nghiệm mua hàng tốt nhất cho các
                        bạn ạ. Nếu mình còn thắc mắc hoặc góp ý, bạn có thể gửi
                        qua nhóm Zalo giải quyết khiếu nại giúp team nha:{' '}
                        <a
                            rel="noreferrer"
                            target="_blank"
                            className="text-orange-600"
                            href="https://zalo.me/g/odqcwk307"
                        >
                            https://zalo.me/g/odqcwk307
                        </a>
                    </p>
                </section>
            </div>
        </div>
    );
}

export default RejectOrderPost;
