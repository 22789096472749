import { faFaceSadTear } from '@fortawesome/free-regular-svg-icons';
import { faPiggyBank } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function ErrorDefault() {
    return (
        <div className="h-dvh flex justify-center items-center">
            <div className=" flex justify-center items-center flex-col gap-4 p-4">
                <div className="flex gap-2">
                    <FontAwesomeIcon
                        icon={faFaceSadTear}
                        className="text-5xl text-gray-400"
                    />
                    <FontAwesomeIcon
                        icon={faPiggyBank}
                        className="rotate-180 text-5xl text-gray-400"
                    />
                </div>
                <p className="text-center">
                    Uii! Lỗi rồi! Bạn vui lòng{' '}
                    <span className="font-semibold text-primary-600">
                        tải lại trang
                    </span>{' '}
                    hoặc{' '}
                    <span className="font-semibold text-primary-600">
                        thử lại sau ít phút.
                    </span>
                </p>
            </div>
        </div>
    );
}

export default ErrorDefault;
