import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import React from 'react';

function VideoGuide({ isFake }) {
    return (
        <div className="p-4 mt-4 w-full  text-primary-600 border rounded-md flex justify-center items-center ">
            {/* <p className="">
                Nếu video không có giỏ, bạn{' '}
                <span className="font-semibold underline">thao tác lại</span>{' '}
                giúp mình hoặc gửi qua{' '}
                <Link
                    target="_blank"
                    to={'https://longhousee.live'}
                    className="underline font-semibold"
                >
                    https://longhousee.live
                </Link>{' '}
                nhé
            </p> */}
            {isFake ? (
                <p className="">
                    <FontAwesomeIcon icon={faWarning} /> Hiện các acc làm video
                    đã bị khoá nên không gắn video được. Nào mở em báo mọi người
                    sau
                </p>
            ) : (
                <div className="text-sm ">
                    <p className="font-bold text-center">
                        ⚠️ KHÔNG ĐỌC HẾT -{'>'} KHÔNG TẠO ĐƯỢC VIDEO
                    </p>
                    <ul className="list-decimal p-4 space-y-2">
                        <li>
                            Khuyến khích gắn 6 sản phẩm để tạo video. Tạo được
                            video xong vào lấy thêm giỏ luôn vì AD sẽ xóa sau
                            vài phút.
                        </li>
                        <li>
                            Nếu bị lỗi code 500, thử tạo lại vài lần là được.
                            Quá 5 phút không được -{'>'} bay acc 3
                        </li>
                        <li>
                            Nếu vào video ko có giỏ thì là do Shopee quét, tạo
                            lại và vào thật nhanh nhé
                        </li>
                        <li>
                            Vào nhóm Zalo, Tele bên dưới đợi thông báo mở gắn
                            video từ Admin. Khi nào bài đăng có link dẫn tới đây
                            là auto gắn được video
                        </li>
                        <li>
                            AD cần mua acc đăng video gắn được sản phẩm giá 3 -
                            5 triệu/ tài khoản. Ai có inbox AD gấp, chi tiết tại{' '}
                            <Link
                                className="font-bold underline"
                                to={'https://zalo.me/g/rwagvz280'}
                                target="_blank"
                            >
                                https://zalo.me/g/rwagvz280
                            </Link>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
}

export default VideoGuide;
