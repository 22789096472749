import React, { useState } from 'react';
import Policy from './Policy';

function ShopeeNote() {
    const [isOpen, setIsOpen] = useState(false);
    const onOpen = () => {
        setIsOpen((prev) => !prev);
    };
    return (
        <div className="text-sm w-full cursor-pointer" onClick={onOpen}>
            <h4 className="text-center bg-amber-200 p-1">
                <p>Số tiền hoàn có thể thấp hơn thực tế hoặc bằng 0đ</p>
                <p>
                    Vui lòng đọc kỹ{' '}
                    <span className="font-bold underline">
                        Điều kiện & Điều khoản
                    </span>{' '}
                    trước khi mua hàng
                </p>
            </h4>
            {isOpen && <Policy />}
        </div>
    );
}

export default ShopeeNote;
