import {
    faFacebook,
    faTelegram,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faZ } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
export const linkgroup = {
    facebook: 'https://www.facebook.com/groups/longhousee',
    telegram: 'https://t.me/longhousee',
    zalo: 'https://zalo.me/g/fdnvdr583',
    zaloSupport: 'https://zalo.me/longhousee',
    youtube: 'https://www.youtube.com/watch?v=0qU_g5vmrxs',
    zaloLonghousee: 'https://zalo.me/0348236765',
};
function JoinGroupV2({
    title,
    linkZalo,
    linkTele,
    linkFb,
    hideFb,
    hideYt,
    linkYoutube,
}) {
    return (
        <div className="grid grid-cols-3 h-36 gap-2 py-4 relative text-sm text-center">
            {!hideFb && (
                <Link
                    target="_blank"
                    className="w-12 aspect-square flex justify-center items-center hover:bg-slate-200 rounded-full"
                    to={linkFb || linkgroup.facebook}
                >
                    <FontAwesomeIcon
                        icon={faFacebook}
                        className="text-blue-600"
                    />
                </Link>
            )}
            <Link
                target="_blank"
                className="flex flex-col w-full justify-center items-center bg-white h-40 rounded-md shadow-lg drop-shadow-lg shadow-primary-500 border"
                to={linkTele || linkgroup.telegram}
            >
                <span>Nhóm Săn Mã</span>
                <FontAwesomeIcon
                    icon={faTelegram}
                    className="text-blue-400 text-4xl"
                />
                <span className="text-base">Telegram</span>
            </Link>
            <Link
                target="_blank"
                className="flex flex-col w-full justify-center items-center bg-white h-40 rounded-md shadow-lg drop-shadow-lg shadow-primary-500 border"
                to={linkZalo || linkgroup.zalo}
            >
                <span>Nhóm Săn Mã</span>
                <FontAwesomeIcon
                    icon={faZ}
                    className="text-blue-500 text-4xl"
                />
                <span className="text-base">Zalo</span>
            </Link>
            {!hideYt && (
                <Link
                    target="_blank"
                    className="flex flex-col w-full justify-center items-center bg-white h-40 rounded-md shadow-lg drop-shadow-lg shadow-primary-500 border"
                    to={linkYoutube || linkgroup.youtube}
                >
                    <span>Hướng dẫn</span>
                    <FontAwesomeIcon
                        icon={faYoutube}
                        className="text-red-500 text-4xl"
                    />
                    <span className="text-base">Youtube</span>
                </Link>
            )}
        </div>
    );
}

export default JoinGroupV2;
