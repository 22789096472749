import React, { useEffect, useState } from 'react';

import VideoGuide from '../VideoGuide';
import Header from '../../../../components/Header';
import ProductVideoInput from './ProductInput';
import MyVideos from '../MyVideos';
import shopeeVideoApi from '../../../../api/shopeeVideoApi';
import handleErrorAsync from '../../../../utils/handleErrorAsync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import JoinGroupV2 from '../../../Home/JoinGroupV2';

function ShopeeVideoMultiLink({ isFake }) {
    const [videoList, setVideoList] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [productList, setProductList] = useState(
        localStorage.getItem('videoProductList')
            ? JSON.parse(localStorage.getItem('videoProductList'))
            : []
    );
    const [isLoading, setIsLoading] = useState(false);

    const fetchVideos = async () => {
        try {
            const { data } = await shopeeVideoApi.myVideos();
            setVideoList(data.videos);
        } catch (error) {
            console.log(error);
        }
    };

    const onCreateVideo = async () => {
        if (isFake) return;
        if (productList.length === 0) return;
        setIsLoading(true);
        try {
            await shopeeVideoApi.uploadVideoV3(productList);
            await fetchVideos();
        } catch (error) {
            console.log(error);
            handleErrorAsync(error, setErrorMsg);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (isFake) return;
        const fetchVideos = async () => {
            try {
                const { data } = await shopeeVideoApi.myVideos();
                setVideoList(data.videos);
            } catch (error) {
                console.log(error);
            }
        };
        fetchVideos();
    }, [isFake]);

    const onRemoveProductList = async () => {
        localStorage.removeItem('videoProductList');
        setProductList([]);
    };
    return (
        <div className="h-dvh flex flex-col">
            <Header />
            <div className="pt-16 flex flex-col p-4 gap-2 md:w-[768px] lg:w-[1024px] md:mx-auto">
                <VideoGuide isFake={isFake} />
                <ProductVideoInput
                    productList={productList}
                    setProductList={setProductList}
                />
                {errorMsg && (
                    <div className="py-2 flex items-center gap-1 text-red-600 text-sm">
                        <FontAwesomeIcon icon={faXmarkCircle} />
                        <span>{errorMsg}</span>
                    </div>
                )}
                {productList.length > 0 && (
                    <div className="p-2">
                        <div className="flex gap-2 items-center">
                            <h3 className="font-semibold py-2">{`Danh sách sản phẩm (${productList.length}/6)`}</h3>
                            <div>
                                <button
                                    onClick={onRemoveProductList}
                                    className="px-1 rounded-lg bg-gray-300 shadow-sm"
                                >
                                    Xóa tất cả
                                </button>
                            </div>
                        </div>
                        <div className="p-2 bg-white rounded-lg border shadow-md flex flex-col gap-3">
                            {productList.map((product) => (
                                <div
                                    key={product.itemId}
                                    className="flex gap-2 border-b last:border-none"
                                >
                                    <img
                                        className="h-12 w-12 rounded object-cover"
                                        src={product.imageUrl}
                                        alt="product"
                                    />
                                    <span className="line-clamp-2 overflow-hidden text-ellipsis">
                                        {product.productName}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-end py-2 w-full">
                            <button
                                disabled={isLoading}
                                onClick={onCreateVideo}
                                className="w-24 h-12 bg-primary-600 text-white rounded-lg border shadow-lg flex justify-center items-center"
                            >
                                {isLoading ? (
                                    <FontAwesomeIcon
                                        icon={faSpinner}
                                        className="animate-spin"
                                    />
                                ) : (
                                    'Tạo Video'
                                )}
                            </button>
                        </div>
                    </div>
                )}
                <h3 className="py-2 px-1 font-semibold">
                    Video gần đây của bạn
                </h3>
                <div>
                    <MyVideos
                        videoList={videoList}
                        setVideoList={setVideoList}
                    />
                </div>
                <JoinGroupV2
                    hideFb={true}
                    linkZalo={'https://zalo.me/g/lygkzy393'}
                    linkTele={'https://t.me/longhousee'}
                    title={
                        'Đừng quên tham gia nhóm Zalo và Telegram cùng tụi mình để nhận thông tin mã video hot mỗi ngày nhé'
                    }
                />
            </div>
        </div>
    );
}

export default ShopeeVideoMultiLink;
