import React from 'react';

function LongHouseePet({ platform }) {
    return (
        <div className="bg-white flex-col flex justify-center items-center p-2">
            <div className="w-40">
                <img src="img/ui/longicon.png" alt="longicon" />
            </div>
            <div className="text-center px-12">
                <p>
                    {` Nhập link ${
                        platform || ''
                    } để Longhousee tìm sản phẩm và hoàn tiền cho
                    nhen!`}
                </p>
            </div>
        </div>
    );
}

export default LongHouseePet;
