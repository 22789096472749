import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faWarning } from '@fortawesome/free-solid-svg-icons';

import ShopeeNote from './ShopeeNote';
import { Link } from 'react-router-dom';
import ProductCardMain from './ProductCardMain';
// import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';

function SearchResultV2({
    productList,
    isLoading,
    platform,
    notFoundMsg,
    fromColorBg,
    toColorBg,
    buyBtnTextColor,
    buyBtnBgColor,
    cashBackTextColor,
}) {
    return (
        <div className="">
            <div className="flex gap-1 justify-start items-center p-2">
                <div
                    className={`h-10 aspect-square bg-gradient-to-br p-2 ${
                        fromColorBg || 'from-orange-500'
                    } ${toColorBg || 'to-orange-600'} rounded-full`}
                >
                    <img
                        src="img/ui/fire.svg"
                        alt="infomation"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="flex flex-col text-sm text-gray-700">
                    <span className="font-bold">KẾT QUẢ TÌM KIẾM</span>
                </div>
            </div>
            <div className="flex flex-wrap px-2 w-full">
                {productList.map((el, index) => (
                    <div key={index} className="p-1 w-full">
                        <ProductCardMain
                            productInfo={el}
                            platform={platform}
                            buyBtnTextColor={buyBtnTextColor}
                            buyBtnBgColor={buyBtnBgColor}
                            cashBackTextColor={cashBackTextColor}
                        />
                    </div>
                ))}
                {isLoading && (
                    <div className="p-1 w-full">
                        <ProductCardMain
                            isLoading={isLoading}
                            productInfo={{}}
                        />
                    </div>
                )}
            </div>
            {productList.length > 0 && (
                <div>
                    <ShopeeNote />
                </div>
            )}
            {productList.length === 0 && !isLoading && (
                <div className="flex justify-center items-center flex-col gap-1 text-slate-400 text-4xl py-6 px-4">
                    <FontAwesomeIcon icon={faBox} />
                    <span className="text-sm text-center">
                        {notFoundMsg || (
                            <div>
                                <p>
                                    Ui tiếc quá, mặt hàng này của bạn không được
                                    hoàn tiền rồi. Nhờ bạn báo lỗi cho
                                    Longhousee để team fix nhé. Báo lỗi tại{' '}
                                    <Link
                                        className="font-bold underline text-primary-600"
                                        to={'https://zalo.me/g/odqcwk307'}
                                        target="_blank"
                                    >
                                        https://zalo.me/g/odqcwk307
                                    </Link>
                                </p>
                            </div>
                        )}
                    </span>
                </div>
            )}
        </div>
    );
}

export default SearchResultV2;
