import React from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';

function Favorites() {
    return (
        <div className="bg-white p-2 flex flex-col gap-1">
            <div className="flex items-center gap-2">
                <div className="w-10 aspect-square bg-[#f1582c] rounded-full p-2">
                    <SVG
                        src="img/ui/util.svg"
                        width={'auto'}
                        height={'auto'}
                        fill="white"
                    />
                </div>
                <div className="flex gap-1">
                    <span className="font-bold text-[1.1rem]">
                        DANH MỤC TIỆN ÍCH
                    </span>
                </div>
            </div>
            <div className="flex justify-center items-start py-4 gap-2 text-xs md:text-base">
                <Link
                    to={'top_cash_back'}
                    className="flex flex-col justify-center items-center w-full aspect-square text-center gap-1"
                >
                    <div className="bg-gradient-to-br from-[#fd6b64] to-[#ff8243] w-4/5 aspect-square rounded-md p-5">
                        <SVG
                            src="img/ui/ranking2.svg"
                            width={'auto'}
                            height={'auto'}
                            fill="white"
                        />
                    </div>
                    <div className="md:flex md:gap-1 font-bold">
                        <p>Đua top</p>
                        <p>hoàn tiền</p>
                    </div>
                </Link>
                <div className="flex flex-col justify-center items-center w-full aspect-square text-center gap-1">
                    <div className="bg-gradient-to-br from-[#fd6b64] to-[#ff8243] w-4/5 aspect-square rounded-md p-3 overflow-hidden">
                        <img
                            src="img/ui/san_voucher.png"
                            alt="voucher"
                            className="object-contain w-full h-full"
                        />
                    </div>
                    <div className="md:flex md:gap-1 font-bold">
                        <p>Đổi Voucher</p>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center w-full aspect-square text-center gap-1">
                    <div className="bg-gradient-to-br from-[#fd6b64] to-[#ff8243] w-4/5 aspect-square rounded-md p-5">
                        <SVG
                            src="img/ui/checkin.svg"
                            width={'auto'}
                            height={'auto'}
                            fill="white"
                        />
                    </div>
                    <div className="md:flex md:gap-1 font-bold">
                        <p>Checkin</p>
                        <p>nhận quà</p>
                    </div>
                </div>
                <Link
                    to={'/post/videolist'}
                    className="flex flex-col justify-center items-center w-full aspect-square text-center gap-1"
                >
                    <div className="bg-gradient-to-br from-[#fd6b64] to-[#ff8243] w-4/5 aspect-square rounded-md p-2 overflow-hidden">
                        <img
                            src="img/ui/shopeevideo.png"
                            className="object-cover h-full w-full"
                            alt="videoshopee"
                        />
                    </div>
                    <div className="md:flex md:gap-1 font-bold">
                        <p>Video độc quyền</p>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default Favorites;
