import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

function PendingLive({ setOpenModal }) {
    const ZALO_LIVE_GROUP = 'https://zalo.me/g/uxtvkq172';
    return (
        <div className="animate-zoomIn w-4/5 md:w-2/3 lg:w-2/5 aspect-square flex flex-col justify-between items-center bg-white rounded-lg shadow-sm border gap-2 p-2">
            <div className="flex flex-1 flex-col justify-center items-center">
                <div className="flex flex-col justify-center items-center">
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-3xl"
                    />
                    <p>Đặt lịch Live thành công</p>
                </div>
                <div className="flex flex-col items-center justify-center gap-3">
                    <p className="text-center">
                        Vô nhóm Zalo để cập nhật lịch LIVE nhé
                    </p>
                    <Link
                        to={ZALO_LIVE_GROUP}
                        className="p-2 bg-primary-600 text-white rounded-lg"
                    >
                        Zô Ngay
                    </Link>
                </div>
            </div>
            <button
                onClick={() => setOpenModal(false)}
                className="p-3 rounded-lg border w-full"
            >
                Đóng
            </button>
        </div>
    );
}

export default PendingLive;
