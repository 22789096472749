import VideoProductCard from './VideoProductCard';

function MyVideos({ videoList }) {
    return (
        <div className="flex h-full flex-col gap-2 overflow-auto">
            {videoList.map((el, index) => (
                <div key={el.itemId}>
                    <VideoProductCard product={el} />
                </div>
            ))}
        </div>
    );
}

export default MyVideos;
