import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import SearchInputV2 from '../../../components/Ecommerce/SearchInputV2';
import SearchResultV2 from '../../../components/Ecommerce/SearchResultV2';
import FooterV2 from '../../../components/FooterV2';
import CashBackGuide from '../../../components/Ecommerce/CashBackGuide';
import tiktokApi from '../../../api/tiktokApi';
import FindButton from '../../../components/Ecommerce/FindButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import AnswerQuestion from '../../../components/Q&A/Q&A';
import { Link } from 'react-router-dom';
import LongHouseePet from '../../../components/Ecommerce/LongHouseePet';
import PolicyTikTok from '../../../components/Ecommerce/PolicyTikTok';
const guideVideoUrl = 'https://www.youtube.com/watch?v=0qU_g5vmrxs';
function TikTokNew() {
    const [searchTerm, setSearchTerm] = useState('');
    const [productList, setProductList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!searchTerm) return;
        setProductList([]);
        const fetchProductInfoByLink = async () => {
            setIsLoading(true);
            try {
                const { data } = await tiktokApi.getProductInfo(searchTerm);
                const productInfo = data.productInfo;
                const { itemId } = productInfo;
                await tiktokApi.addToShowCase(itemId);
                setProductList(() => [productInfo]);
            } catch (error) {
                console.log(error);
                setProductList([]);
            }
            setIsLoading(false);
        };
        fetchProductInfoByLink();
    }, [searchTerm]);

    return (
        <div className="h-dvh flex flex-col justify-between">
            <Header />
            <div className="pt-16 w-full md:w-[768px] lg:w-[1024px] mx-auto flex flex-col gap-2">
                <div>
                    <SearchInputV2
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        isLoading={isLoading}
                        platform={'tiktok'}
                        cashBackTitle={'Hoàn tiền đến 20.02%'}
                        titleTextColor={'text-rose-800'}
                        borderInputColor={'border-rose-800'}
                    />
                </div>
                {!searchTerm && <LongHouseePet />}
                {searchTerm && (
                    <SearchResultV2
                        isLoading={isLoading}
                        platform={'tiktok'}
                        productList={productList}
                        fromColorBg={'from-rose-500'}
                        toColorBg={'to-rose-800'}
                        buyBtnBgColor={'bg-rose-800'}
                        buyBtnTextColor={'text-rose-800'}
                        cashBackTextColor={'text-white'}
                    />
                )}

                <>
                    <FindButton
                        fromColorBg={'from-rose-200'}
                        toColorBg={'to-rose-900'}
                        fromColorText={'from-black'}
                        toColorText={'to-black'}
                        platform={'tiktok'}
                    />
                    <div className="p-2 bg-white text">
                        <CashBackGuide
                            fromColorBg={'from-rose-500'}
                            toColorBg={'to-rose-800'}
                            platform="tiktok"
                        />
                    </div>
                    <div className="h-28 flex justify-between bg-white">
                        <div className="flex justify-center text-sm items-center flex-col w-1/2 font-semibold text-center">
                            <p className="">VIDEO HƯỚNG DẪN</p>
                            <p> HOÀN TIỀN TỪ TIKTOK</p>
                        </div>
                        <div className="p-2 w-1/2">
                            <Link
                                to={guideVideoUrl}
                                target="_blank"
                                className="h-full overflow-hidden relative"
                            >
                                <img
                                    src="img/longhousee_guide_thumb.jpeg"
                                    alt="video"
                                    className="h-full w-full object-cover rounded-lg"
                                />
                                <div className="absolute top-0 h-full w-full opacity-30 bg-gray-500 rounded-lg"></div>
                                <div className="absolute top-0 h-full w-full flex justify-center items-center text-white text-4xl">
                                    <FontAwesomeIcon icon={faPlayCircle} />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <PolicyTikTok
                        fromColorBg={'from-rose-500'}
                        toColorBg={'to-rose-800'}
                    />
                </>
                <div className="p-2">
                    <AnswerQuestion
                        postId={12}
                        fromColorBg={'from-rose-500'}
                        toColorBg={'to-rose-800'}
                    />
                </div>
            </div>

            <FooterV2 />
        </div>
    );
}

export default TikTokNew;
