import {
    faCircleNotch,
    faPlus,
    faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import shopeApi from '../../../../api/shopeeApi';
import handleErrorAsync from '../../../../utils/handleErrorAsync';

function ProductVideoInput({ productList, setProductList }) {
    const [errorMsg, setErrorMsg] = useState('');
    const [link, setLink] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onInput = (e) => {
        setErrorMsg('');
        setLink(e.target.value);
    };

    const onAddLink = async () => {
        if (!link) return;
        setIsLoading(true);
        try {
            const { data } = await shopeApi.getProductInfo(link);
            const productExisted = productList.find(
                (product) => product.itemId === data.productInfo.itemId
            );
            if (productExisted) {
                setErrorMsg('Sản phẩm đã có trong giỏ');
                setIsLoading(false);
                setLink('');
                return;
            }
            localStorage.setItem(
                'videoProductList',
                JSON.stringify([data.productInfo, ...productList])
            );
            setProductList((prev) => [data.productInfo, ...prev]);
            setLink('');
        } catch (error) {
            console.log(error);
            handleErrorAsync(error, setErrorMsg);
        }
        setIsLoading(false);
    };
    return (
        <div className="w-full p-2">
            <div className="w-full h-12 flex gap-2">
                <input
                    value={link}
                    onChange={onInput}
                    placeholder="Dán link sản phẩm"
                    type="text"
                    className="p-3 w-full border rounded-lg shadow-md focus:border-orange-500"
                />
                <button
                    disabled={isLoading}
                    onClick={onAddLink}
                    className="h-full aspect-square bg-primary-600 text-white rounded-md border drop-shadow-md disabled:opacity-60"
                >
                    {isLoading ? (
                        <FontAwesomeIcon
                            icon={faCircleNotch}
                            className="animate-spin"
                        />
                    ) : (
                        <FontAwesomeIcon icon={faPlus} />
                    )}
                </button>
            </div>
            {errorMsg && (
                <div className="py-2 flex items-center gap-1 text-red-600 text-sm">
                    <FontAwesomeIcon icon={faXmarkCircle} />
                    <span>{errorMsg}</span>
                </div>
            )}
        </div>
    );
}

export default ProductVideoInput;
