import {
    faEye,
    faEyeSlash,
    faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

function Input({
    input,
    setInput,
    className,
    isClearable,
    placeholder,
    type,
    autoFocus,
    hasEye,
    autoCapitalize,
}) {
    const [eye, setEye] = useState(false);
    const [typeInput, setTypeInput] = useState(type);

    const toggleEye = () => {
        setEye((prev) => !prev);
        setTypeInput(eye ? 'password' : 'text');
    };
    const handleInput = ({ target }) => {
        setInput(target.value);
    };

    const handleClearInput = () => {
        setInput('');
    };
    const defaultClassName =
        'bg-transparent border-b py-3 w-full border-gray-400 outline-none focus-within:outline-none';
    return (
        <div className="relative">
            <input
                value={input}
                onChange={handleInput}
                type={typeInput || 'text'}
                className={className || defaultClassName}
                placeholder={placeholder}
                autoFocus={autoFocus}
                autoCapitalize={autoCapitalize ? 'none' : 'off'}
            />
            {isClearable && input && (
                <FontAwesomeIcon
                    className="text-sm absolute top-1/2 -translate-y-1/2 right-1 text-gray-400 hover:text-slate-900"
                    icon={faXmarkCircle}
                    onClick={handleClearInput}
                />
            )}
            {hasEye && input && (
                <FontAwesomeIcon
                    onClick={toggleEye}
                    icon={eye ? faEyeSlash : faEye}
                    className="absolute top-1/2 -translate-y-1/2 right-1 text-sm text-gray-500"
                />
            )}
        </div>
    );
}

export default Input;
