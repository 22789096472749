import { faTshirt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function SearchResult({ liveProducts }) {
    return (
        <div className="flex flex-col rounded-lg overflow-hidden shadow-lg">
            {liveProducts.length > 0 &&
                liveProducts.map((el, index) => (
                    <div
                        key={index}
                        className="h-16 w-full flex justify-between  p-1 bg-white border-b last:border-none"
                    >
                        <div className="h-full aspect-square flex justify-center items-center">
                            {el.imageUrl ? (
                                <img
                                    className="h-full object-cover rounded-md"
                                    src={el.imageUrl}
                                    alt="product"
                                />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faTshirt}
                                    className="text-2xl text-slate-500"
                                />
                            )}
                        </div>
                        <div className="">
                            <p className="overflow-hidden line-clamp-2 text-ellipsis text-sm px-1">
                                {el.productName}
                            </p>
                        </div>
                        <div className="h-full aspect-square p-1 font-bold text-center flex justify-center items-center">
                            <span>{el.idOfLive || '---'}</span>
                        </div>
                    </div>
                ))}
        </div>
    );
}

export default SearchResult;
