import React, { useRef, useState } from 'react';

function LuckyWheel() {
    const [isSpinning, setIsSpinning] = useState(false);
    const [rotate, setRotate] = useState(0);
    const valueList = [
        {
            key: 1,
            degRange: [0, 59],
            content: 'Số 1',
            deg: 30,
            color: 'bg-rose-500',
        },
        {
            key: 2,
            degRange: [60, 119],
            content: 'Số 2',
            deg: 90,
            color: 'bg-amber-400',
        },
        {
            key: 3,
            degRange: [120, 179],
            content: 'Số 3',
            deg: 150,
            color: 'bg-green-400',
        },
        {
            key: 4,
            degRange: [180, 239],
            content: 'Số 4',
            deg: 210,
            color: 'bg-indigo-400',
        },
        {
            key: 5,
            degRange: [240, 299],
            content: 'Số 5',
            deg: 270,
            color: 'bg-purple-400',
        },
        {
            key: 6,
            degRange: [300, 359],
            content: 'Số 6',
            deg: 330,
            color: 'bg-pink-500',
        },
    ];
    const wheelRef = useRef();
    const onSpin = () => {
        if (isSpinning) return;
        setIsSpinning(true);
        const newRotage = rotate + 3600 - convertRotage(rotate, 4);
        setRotate(newRotage);
    };

    const onStopWhell = () => {
        setIsSpinning(false);
    };

    const convertRotage = (currentRotage, value) => {
        const resultValue = valueList.find((el) => el.key === value);
        const deg =
            resultValue.degRange[0] +
            Math.ceil(
                Math.random() *
                    (resultValue.degRange[1] - resultValue.degRange[0])
            );

        return deg + (currentRotage % 360);
    };
    return (
        <div className="h-dvh flex justify-center items-center">
            <div
                className={`relative overflow-hidden w-3/4 md:w-2/3 lg:w-1/4 aspect-square bg-white border-[10px] shadow-inner transition transform duration-[8000ms] ease-in-out border-white rounded-full drop-shadow-lg flex justify-center items-center`}
            >
                <div
                    ref={wheelRef}
                    onClick={onSpin}
                    onTransitionEnd={onStopWhell}
                    style={{
                        transform: `rotate(${rotate}deg)`,
                    }}
                    className={`relative overflow-hidden  w-full h-full transition transform duration-[8000ms] ease-in-out border-white rounded-full drop-shadow-lg flex justify-center items-center`}
                >
                    {valueList.map((el) => (
                        <div
                            style={{
                                transform: `rotate(${el.deg}deg)`,
                            }}
                            key={el.key}
                            className={`${el.color} h-1/2 w-[57.3%] absolute -translate-x-1/2 top-0 [clip-path:polygon(100%_0%,50%_100%,0%_0%)] origin-bottom flex justify-center items-center`}
                        >
                            {el.content}
                        </div>
                    ))}
                </div>
                <div className="absolute w-10 h-10 top-[-10px] z-20 left-1/2 -translate-x-1/2 [clip-path:polygon(100%_0%,50%_100%,0%_0%)] bg-primary-400"></div>
            </div>
        </div>
    );
}

export default LuckyWheel;
