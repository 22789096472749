import React from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/bundle';

function CashBackGuide({ fromColorBg, toColorBg, platform, borderColor }) {
    const slideList2 = {
        shopee: [
            {
                step: 1,
                content: (
                    <p>
                        Tại trang sản phẩm trên Shopee, bấm vào{' '}
                        <strong>Mũi tên</strong> ở góc phía bên phải
                    </p>
                ),
            },
            {
                step: 2,
                content: (
                    <p>
                        Ở hộp thoại mở lên, chọn{' '}
                        <strong>Sao chép đường dẫn</strong>
                    </p>
                ),
            },
            {
                step: 3,
                content: (
                    <p>
                        Quay về longhousee.com <strong>Dán link</strong> vừa sao
                        chép vào <strong>Khung tìm kiếm</strong> phía trên
                    </p>
                ),
            },
            {
                step: 4,
                content: (
                    <p>
                        Nhấn <strong>Mua ngay</strong> để mở app Shopee và được
                        hoàn tiền
                    </p>
                ),
            },
        ],
        lazada: [
            {
                step: 1,
                content: (
                    <p>
                        Tại trang sản phẩm trên Lazada, bấm vào{' '}
                        <strong>Mũi tên</strong> ở góc phía bên phải
                    </p>
                ),
            },
            {
                step: 2,
                content: (
                    <p>
                        Ở hộp thoại mở lên, chọn{' '}
                        <strong>Sao chép đường dẫn</strong>
                    </p>
                ),
            },
            {
                step: 3,
                content: (
                    <p>
                        Quay về longhousee.com <strong>Dán link</strong> vừa sao
                        chép vào <strong>Khung tìm kiếm</strong> phía trên
                    </p>
                ),
            },
            {
                step: 4,
                content: (
                    <p>
                        Nhấn <strong>Mua ngay</strong> để mở app Lazada và được
                        hoàn tiền
                    </p>
                ),
            },
        ],
        tiktok: [
            {
                step: 1,
                content: (
                    <p>
                        Tại trang sản phẩm trên Tiktok, bấm vào{' '}
                        <strong>Mũi tên</strong> ở góc phía bên phải và nhấn{' '}
                        <strong>Sao chép liên kết</strong>
                    </p>
                ),
            },
            {
                step: 2,
                content: (
                    <p>
                        Quay về longhousee.com <strong>Dán link</strong> vừa sao
                        chép vào <strong>Khung tìm kiếm</strong> phía trên, sau
                        đó bấm <strong>Mua ngay</strong>
                    </p>
                ),
            },
            {
                step: 3,
                content: (
                    <p>
                        Chọn <strong>Phần trưng bày</strong> trên kênh tiktok
                        Săn sale cùng Longhousee và tìm <strong>Mua</strong> sản
                        phẩm của bạn
                    </p>
                ),
            },
            {
                step: 4,
                content: (
                    <p>
                        Sao chép <strong>Số đơn hàng</strong> đã đặt sau đó quay
                        trở lại trang nhấn <strong>Tìm đơn</strong>
                    </p>
                ),
            },
        ],
    };
    return (
        <div className="flex flex-col gap-3">
            <div className="flex gap-1 justify-start items-center">
                <div
                    className={`h-10 aspect-square bg-gradient-to-br p-2 ${
                        fromColorBg || 'from-orange-500'
                    } ${toColorBg || 'to-orange-600'} rounded-full`}
                >
                    <img
                        src="img/ui/information_white.svg"
                        alt="infomation"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="flex flex-col text-sm text-gray-700">
                    <span className="font-bold">4 bước</span>
                    <span className="font-bold">MUA SẮM HOÀN TIỀN</span>
                </div>
            </div>
            <div>
                <Swiper
                    modules={[Autoplay]}
                    className="h-full rounded-lg overflow-hidden"
                    spaceBetween={4}
                    slidesPerView={2.1}
                    autoplay
                    pagination={{
                        clickable: true,
                        renderBullet: function (index, className) {
                            return (
                                '<span class="' +
                                className +
                                ' custom-pagination-bullet"></span>'
                            );
                        },
                    }}
                >
                    {slideList2[platform || 'shopee'].map((el, index) => (
                        <SwiperSlide key={index} className="">
                            <div>
                                <div
                                    className={`w-full bg-red-400 border rounded-lg overflow-hidden ${
                                        borderColor || 'border-primary-600'
                                    }`}
                                >
                                    <img
                                        src={`img/ui/guide/${
                                            platform || 'tiktok'
                                        }/${el.step}.png`}
                                        alt="guide"
                                    />
                                </div>
                                <div className="text-xs md:text-sm">
                                    <span className="font-bold">{`Bước ${el.step}`}</span>
                                    <p>{el.content}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default CashBackGuide;
