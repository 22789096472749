import {
    faCalendarCheck,
    faCheckCircle,
    faClock,
    faXmarkCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
    faCartPlus,
    faChevronDown,
    faInfo,
    faMobileScreenButton,
    faPodcast,
    faSackDollar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

function PolicyTikTok({ fromColorBg, toColorBg }) {
    const [isOpenMore, setIsOpenMore] = useState(false);
    return (
        <div onClick={(e) => e.stopPropagation()} className="bg-white p-2">
            <div className="flex gap-1 justify-start items-center">
                <div
                    className={`h-10 p-2 aspect-square bg-gradient-to-br ${
                        fromColorBg || 'from-orange-500'
                    } ${toColorBg || 'to-orange-600'} rounded-full`}
                >
                    <img
                        src="img/ui/activity_white.svg"
                        alt="policy"
                        className="h-full w-full object-cover"
                    />
                </div>
                <div className="flex flex-col text-sm text-gray-700">
                    <span>Tham khảo</span>
                    <span className="font-bold">ĐIỀU KHOẢN HOÀN TIỀN</span>
                </div>
            </div>
            <div className="bg-white">
                <div className="p-2">
                    <h3 className="text-xs font-bold text text-orange-600 py-1">
                        THỜI GIAN HOÀN TIỀN
                    </h3>
                    <div className="rounded-xl border">
                        <div className="flex items-center border-b last:border-none">
                            <div className="h-14 aspect-square flex justify-center items-center text-center text-xl">
                                <FontAwesomeIcon icon={faClock} />
                            </div>
                            <p className="text-xs">
                                Thời gian cập nhật đơn hàng là 24 - 48H kể từ
                                khi đặt hàng thành công. Nếu sau 48H không thấy
                                hiển thị đơn tức là đơn không được ghi nhận trên
                                app.
                            </p>
                        </div>
                        <div className="flex items-center border-b last:border-none">
                            <div className="h-14 aspect-square flex justify-center items-center text-center text-xl">
                                <FontAwesomeIcon icon={faCalendarCheck} />
                            </div>
                            <p className="text-xs">
                                Thời gian duyệt đơn hàng Shopee là 30 ngày,
                                Lazada là 45 ngày và Tiktok là 7 ngày kể từ khi
                                khách hàng bấm Đã nhận hàng trên app.
                            </p>
                        </div>
                        <div className="flex items-center border-b last:border-none">
                            <div className="h-14 aspect-square flex justify-center items-center text-center text-xl">
                                <FontAwesomeIcon icon={faSackDollar} />
                            </div>
                            <p className="text-xs">
                                Số dư hoàn tiền có thể rút tối thiểu là 10.000đ
                                và khả dụng rút số dư vào 10H ngày 18, 28, 29
                                hàng tháng
                            </p>
                        </div>
                    </div>
                </div>
                {!isOpenMore && (
                    <div className="flex justify-center items-center">
                        <button
                            onClick={() => setIsOpenMore(true)}
                            className="bg-gray-200 py-1 px-2 w-40 flex justify-center items-center gap-2 rounded"
                        >
                            <span> XEM THÊM </span>
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                className="text-sm"
                            />
                        </button>
                    </div>
                )}

                {isOpenMore && (
                    <>
                        <div className="p-2">
                            <h3 className="text-xs font-bold text text-orange-600 py-1">
                                ĐIỀU KIỆN CHẤP NHẬN HOÀN TIỀN
                            </h3>
                            <div className="rounded-xl border">
                                <div className="flex items-center border-b last:border-none">
                                    <div className="h-14 aspect-square flex justify-center items-center text-center text-xl">
                                        <FontAwesomeIcon icon={faInfo} />
                                    </div>
                                    <p className="text-xs">
                                        Hoàn tiền được tính bằng giá trị đơn
                                        hàng sau khi đã áp mã giảm giá, mã
                                        MPVC... nên số tiền có thể bé hơn dự
                                        kiến trên web.
                                    </p>
                                </div>
                                <div className="flex items-center border-b last:border-none">
                                    <div className="h-14 aspect-square flex justify-center items-center text-center text-xl">
                                        <FontAwesomeIcon icon={faCartPlus} />
                                    </div>
                                    <p className="text-xs">
                                        Để nhận được tối đa số tiền hoàn, bạn
                                        nên mua các sản phẩm cùng Shop. Trường
                                        hợp nhiều sản phẩm ở nhiều Shop khác
                                        nhau, bạn nên tách lẻ đơn của từng Shop.
                                    </p>
                                </div>
                                <div className="flex items-center border-b last:border-none">
                                    <div className="h-14 aspect-square flex justify-center items-center text-center text-xl">
                                        <FontAwesomeIcon
                                            icon={faMobileScreenButton}
                                        />
                                    </div>
                                    <p className="text-xs">
                                        Chỉ chấp nhận các đơn hàng phát sinh
                                        trên điện thoại và mỗi lần mua hàng chỉ
                                        mua 1 đơn duy nhất. Mua đơn thứ 2 trở đi
                                        cần quay trở lại website để dán hoàn
                                        tiền như ban đầu.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2">
                            <h3 className="text-xs font-bold text text-orange-600 py-1">
                                ĐIỀU KIỆN TỪ CHỐI HOÀN TIỀN
                            </h3>
                            <div className="rounded-xl border">
                                <div className="flex items-center justify-start border-b last:border-none">
                                    <div className="h-14 aspect-square flex justify-center items-center text-center text-xl">
                                        <FontAwesomeIcon icon={faPodcast} />
                                    </div>
                                    <p className="text-xs">
                                        Không ghi nhận hoàn tiền cho các đơn
                                        hàng phát sinh từ LiveStream và từ Video
                                        của các nhà sáng tạo nội dung khác.
                                    </p>
                                </div>
                                <div className="flex items-center border-b last:border-none">
                                    <div className="h-14 aspect-square flex justify-center items-center text-center text-xl">
                                        <FontAwesomeIcon icon={faCheckCircle} />
                                    </div>
                                    <p className="text-xs">
                                        Hoàn tiền có thể bị huỷ hoặc bằng OĐ do
                                        chính sách và quy định riêng của từng
                                        sàn.
                                    </p>
                                </div>
                                <div className="flex items-center border-b last:border-none">
                                    <div className="h-14 aspect-square flex justify-center items-center text-center text-xl">
                                        <FontAwesomeIcon icon={faXmarkCircle} />
                                    </div>
                                    <p className="text-xs">
                                        Các đơn hàng có dấu hiệu gian lận, huỷ
                                        đơn, bom hàng, tích trữ hàng hóa, lạm
                                        dụng mã giảm giá,... sẽ bị huỷ hoàn tiền
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default PolicyTikTok;
