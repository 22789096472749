import bankApi from '../../api/bankApi';

const withdrawLoader = async () => {
    try {
        const { data } = await bankApi.getBankInfo();
        return data.data.bankInfo;
    } catch (error) {
        console.log(error);
        return [];
    }
};
export default withdrawLoader;
