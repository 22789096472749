const handleErrorAsync = (error, setErrorMsg) => {
    if (`${error.response?.status}`.startsWith('5')) {
        return setErrorMsg(
            `Đã có lỗi xảy ra, vui lòng thử lại sau (code: ${error.response.status})`
        );
    }
    if (`${error.response?.status}`.startsWith('4')) {
        return setErrorMsg(error.response.data.message);
    }

    return setErrorMsg('Lỗi không xác định, vui lòng thử lại sau');
};

export default handleErrorAsync;
