import React, { useEffect, useState } from 'react';
import authApi from '../../../../api/authApi';
import formattedMoney from '../../../../utils/formatMoney';

function RefUsers() {
    const [refUsers, setRefUsers] = useState([]);
    const [totalRefCommission, setTotalCommisson] = useState(0);

    useEffect(() => {
        const fetchRefs = async () => {
            try {
                const { data } = await authApi.myRefs();
                setRefUsers(data.data.refUsers);
                setTotalCommisson(data.data.totalRefCommission);
            } catch (error) {
                console.log(error);
            }
        };
        fetchRefs();
    }, []);
    return (
        <div className="p-3 flex flex-col gap-2">
            <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-primary-500 rounded-lg shadow-sm">
                <div className="p-3 text-slate-900">
                    <p className="font-bold">Thống kê của bạn</p>
                    <div className="flex gap-2">
                        <span>Đã giới thiệu:</span>
                        <span className="font-semibold">{`${refUsers.length} người dùng`}</span>
                    </div>
                    <div className="flex gap-2">
                        <span>Hoa hồng nhận được:</span>
                        <span className="font-black">
                            {formattedMoney.format(totalRefCommission)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RefUsers;
