import React, { useEffect, useState } from 'react';
import authApi from '../../api/authApi';

function AboutUs({ orderNum }) {
    const [userNum, setUserNum] = useState(0);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const { data } = await authApi.totalUser();
                setUserNum(data.totalUser);
            } catch (error) {
                console.log(error);
            }
        };
        fetchUser();
    }, []);
    return (
        <div className="bg-white">
            <div className="bg-gradient-to-b to-[#fccb7a] from-[#ffeec1] pt-4">
                <div className="text-center">
                    <h3 className="text-[#f1582c] font-bold text-2xl p-3">
                        ABOUT US
                    </h3>
                    <p className="px-[1.1rem]">
                        Longhousee là trang web săn sale, nhận ưu đãi, mà còn là
                        một trang web mua sắm hoàn tiền độc đáo. Khi mua hàng
                        thông qua Longhousee, người dùng sẽ được hoàn lại một
                        phần giá trị giao dịch, giúp tiết kiệm thêm chi phí
                    </p>
                </div>
                <div className="p-8">
                    <img src="img/ui/aboutus.png" alt="aboutus" />
                </div>
            </div>
            <div className="flex justify-between py-4">
                <div className="flex flex-1 flex-col justify-center items-center text-center">
                    <span className="text-2xl text-[#f1582c] font-bold">
                        {orderNum || '1.000.000+'}
                    </span>
                    <span>Đơn hàng</span>
                </div>
                <div className="flex justify-center items-center py-2">
                    <div className="border-l border-gray-400 h-full"></div>
                </div>
                <div className="flex flex-1 flex-col justify-center items-center text-center">
                    <span className="text-2xl text-[#f1582c] font-bold">
                        {userNum || '17.232.233'}
                    </span>
                    <span>Người sử dụng</span>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
