import React from 'react';
import formattedMoney from '../../utils/formatMoney';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import rankName from '../../utils/user/rankName';

function Top4({ users }) {
    return (
        <table className="w-full text-right">
            <thead>
                <tr className="grid grid-cols-4 text-left p-2"></tr>
            </thead>
            <tbody className="flex flex-col gap-1">
                {users.map((el, index) => (
                    <tr
                        key={index}
                        className="grid grid-cols-5 rounded-lg border p-2 items-center"
                    >
                        <td className="flex items-center gap-6 col-span-1">
                            <div
                                className={`w-10 h-10 relative flex items-center`}
                            >
                                <p>{index + 4}</p>
                            </div>
                        </td>
                        <td className="col-span-2 text-center">
                            <div className="flex gap-2 items-center justify-start">
                                <div>
                                    {el.userInfo?.avatar && (
                                        <img
                                            className="h-8 w-8 object-cover rounded-full"
                                            src={`${process.env.REACT_APP_API_PUBLIC_URL}/img/users/${el.userInfo.avatar}`}
                                            alt="avt"
                                        />
                                    )}
                                    {!el.userInfo?.avatar && (
                                        <div className="w-8 h-8 rounded-full bg-slate-50 flex justify-center items-center text-base">
                                            <FontAwesomeIcon icon={faUser} />
                                        </div>
                                    )}
                                </div>
                                <div className="text-left flex flex-col">
                                    <span className="line-clamp-1">
                                        {el.userInfo
                                            ? el.userInfo.name
                                                ? el.userInfo.name
                                                : `user-${el.userId}`
                                            : '---'}
                                    </span>
                                    <div className="text-xs text-left">
                                        {el.userInfo &&
                                            rankName(el.userInfo.rank)}
                                    </div>
                                </div>
                            </div>
                        </td>

                        <td className="col-span-1">
                            {formattedMoney.format(el.totalUserCommission)}
                        </td>
                        <td className="col-span-1 font-bold text-orange-500">
                            {index < 12 ? '+50.000' : '---'}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default Top4;
