const { default: axiosClient } = require('./axiosClient');

const shopeeLiveApi = {
    getSession() {
        const baseUrl = 'shopeelive/live_session';
        return axiosClient.get(baseUrl);
    },
    likeProduct(link) {
        const baseUrl = 'shopeelive/like_item';
        return axiosClient.post(baseUrl, {
            link,
        });
    },
    searchLiveProducts(searchTerm, productId) {
        const baseUrl = 'shopeelive/search_products';
        return axiosClient.post(baseUrl, {
            searchTerm,
            productId,
        });
    },
    getSessionV2(query) {
        const baseUrl = `shopeelive/live_session_v2?${query || ''}`;
        return axiosClient.get(baseUrl);
    },
    searchLiveProductsV2(searchTerm, sessionId) {
        const baseUrl = 'shopeelive/search_products_v2';
        return axiosClient.post(baseUrl, {
            searchTerm,
            sessionId,
        });
    },
    addProductToLive(link, sessionId) {
        const baseUrl = 'shopeelive/add_product_to_session';
        return axiosClient.post(baseUrl, {
            link,
            sessionId,
        });
    },
};

export default shopeeLiveApi;
