import { createSlice } from '@reduxjs/toolkit';
const popupSlice = createSlice({
    name: 'popup',
    initialState: {
        guidePopup: false,
    },
    reducers: {
        closeGuide(state) {
            state.guidePopup = false;
        },
        setGuide(state, action) {
            state.guidePopup = action;
        },
    },
});

const popupActions = popupSlice.actions;
const popupReducer = popupSlice.reducer;

export { popupActions };
export default popupReducer;
