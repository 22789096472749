import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import authApi from '../../api/authApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import rankName from '../../utils/user/rankName';
import Top4 from './Top4';
import { format, getUnixTime, startOfDay } from 'date-fns';

import formattedMoney from '../../utils/formatMoney';
import { Link } from 'react-router-dom';

const TODAY = new Date();
const YEAR = TODAY.getFullYear();
const MONTH = TODAY.getMonth();

function TopCashBackV2() {
    const [users, setUsers] = useState([]);
    const [time, setTime] = useState({
        startTime: getUnixTime(startOfDay(new Date(YEAR, MONTH, 1))),
        endTime: getUnixTime(startOfDay(new Date(YEAR, MONTH + 1, 1))),
    });
    const [isThisTime, setIsThisTime] = useState(true);

    const onChangeTime = (value) => {
        if (value === isThisTime) return;
        setIsThisTime(value);
        if (value) {
            setTime({
                startTime: getUnixTime(startOfDay(new Date(YEAR, MONTH, 1))),
                endTime: getUnixTime(startOfDay(new Date(YEAR, MONTH + 1, 1))),
            });
        } else {
            setTime({
                startTime: getUnixTime(
                    startOfDay(new Date(YEAR, MONTH - 1, 1))
                ),
                endTime: getUnixTime(startOfDay(new Date(YEAR, MONTH, 1))),
            });
        }
    };
    useEffect(() => {
        const fetchUsers = async () => {
            const ONLY_NUM_REGEX = /^\d+$/;
            try {
                const { data } = await authApi.topOfMonth(
                    time.startTime,
                    time.endTime
                );
                if (data.userList.length < 20) {
                    const fillArray = new Array(20 - data.userList.length).fill(
                        {
                            userId: '-',
                            totalUserCommission: 0,
                        }
                    );
                    setUsers([
                        ...data.userList.filter(
                            (el) =>
                                el.userId &&
                                el.userId !== '' &&
                                ONLY_NUM_REGEX.test(el.userId)
                        ),
                        ...fillArray,
                    ]);
                    return;
                }
                setUsers(
                    data.userList
                        .filter(
                            (el) =>
                                el.userId &&
                                el.userId !== '' &&
                                ONLY_NUM_REGEX.test(el.userId)
                        )
                        .splice(0, 20)
                );
            } catch (error) {
                console.log(error);
            }
        };
        fetchUsers();
    }, [time]);

    return (
        <div className="w-full">
            <Header />
            <div>
                <div className="pt-20 p-2 pb-6 bg-white text-sm">
                    <div className="text-center">
                        <h1 className="font-semibold">BẢNG XẾP HẠNG</h1>
                        <h2 className="font-bold text-xl">ĐUA TOP HOÀN TIỀN</h2>
                    </div>
                    {users.length > 0 && (
                        <div className="flex w-full justify-between items-end bg-white p-2">
                            <div className="w-1/3">
                                <div className="flex relative flex-col gap-1 items-center justify-start from-gray-300 pt-2 rounded-t-lg bg-gradient-to-b">
                                    <div>
                                        {users[1]?.userInfo?.avatar && (
                                            <img
                                                className="h-12 w-12 object-cover rounded-full"
                                                src={`${process.env.REACT_APP_API_PUBLIC_URL}/img/users/${users[1]?.userInfo.avatar}`}
                                                alt="avt"
                                            />
                                        )}
                                        {!users[1]?.userInfo?.avatar && (
                                            <div className="w-12 h-12 rounded-full bg-slate-100 flex justify-center items-center text-base">
                                                <FontAwesomeIcon
                                                    icon={faUser}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="text-center flex flex-col">
                                        <span className="line-clamp-1">
                                            {users[1]?.userInfo
                                                ? users[1]?.userInfo.name
                                                    ? users[1]?.userInfo.name
                                                    : `user-${users[1]?.userId}`
                                                : '---'}
                                        </span>
                                        <div className="text-xs text-center">
                                            {users[1]?.userInfo &&
                                                rankName(
                                                    users[1]?.userInfo.rank
                                                )}
                                        </div>
                                        <div>
                                            {formattedMoney.format(
                                                users[1].totalUserCommission
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="h-24 bg-red-600 text-center font-bold text-base text-white  flex flex-col justify-end items-center">
                                    <div className="h-20 p-2 overflow-hidden">
                                        <img
                                            className="object-cover h-12"
                                            src="/img/ui/numberthree.png"
                                            alt="trophy"
                                        />
                                    </div>
                                    <span>+500.000Đ</span>
                                </div>
                            </div>
                            <div className="w-1/3">
                                {/* TOP 1 */}
                                <div className="flex relative flex-col gap-1 items-center justify-start from-amber-300 pt-2 rounded-t-lg bg-gradient-to-b">
                                    <div>
                                        {users[0]?.userInfo?.avatar && (
                                            <img
                                                className="h-12 w-12 object-cover rounded-full"
                                                src={`${process.env.REACT_APP_API_PUBLIC_URL}/img/users/${users[0]?.userInfo.avatar}`}
                                                alt="avt"
                                            />
                                        )}
                                        {!users[0]?.userInfo?.avatar && (
                                            <div className="w-12 h-12 rounded-full bg-slate-100 flex justify-center items-center text-base">
                                                <FontAwesomeIcon
                                                    icon={faUser}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="text-center flex flex-col">
                                        <span className="line-clamp-1">
                                            {users[0]?.userInfo
                                                ? users[0]?.userInfo.name
                                                    ? users[0]?.userInfo.name
                                                    : `user-${users[0]?.userId}`
                                                : '---'}
                                        </span>
                                        <div className="text-xs text-center">
                                            {users[0]?.userInfo &&
                                                rankName(
                                                    users[0]?.userInfo.rank
                                                )}
                                        </div>
                                        <div>
                                            {formattedMoney.format(
                                                users[0].totalUserCommission
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="h-32 bg-red-600 text-center font-bold text-base text-white flex flex-col justify-end items-center">
                                    <div className="h-20 p-2 overflow-hidden">
                                        <img
                                            className="object-cover h-14"
                                            src="/img/ui/trophy2.png"
                                            alt="trophy"
                                        />
                                    </div>
                                    <p>+1.000.000Đ</p>
                                </div>
                            </div>
                            <div className="w-1/3">
                                <div className="flex relative flex-col gap-1 items-center justify-start  from-orange-300 pt-2 rounded-t-lg bg-gradient-to-b">
                                    <div>
                                        {users[2]?.userInfo?.avatar && (
                                            <img
                                                className="h-12 w-12 object-cover rounded-full"
                                                src={`${process.env.REACT_APP_API_PUBLIC_URL}/img/users/${users[2]?.userInfo.avatar}`}
                                                alt="avt"
                                            />
                                        )}
                                        {!users[2]?.userInfo?.avatar && (
                                            <div className="w-12 h-12 rounded-full bg-slate-100 flex justify-center items-center text-base">
                                                <FontAwesomeIcon
                                                    icon={faUser}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="text-center flex flex-col">
                                        <span className="line-clamp-1">
                                            {users[2]?.userInfo
                                                ? users[2]?.userInfo.name
                                                    ? users[2]?.userInfo.name
                                                    : `user-${users[2]?.userId}`
                                                : '---'}
                                        </span>
                                        <div className="text-xs text-center">
                                            {users[2]?.userInfo &&
                                                rankName(
                                                    users[2]?.userInfo.rank
                                                )}
                                        </div>
                                        <div>
                                            {formattedMoney.format(
                                                users[2].totalUserCommission
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="h-24 bg-red-600 text-center font-bold text-base text-white  flex flex-col justify-end items-center">
                                    <div className="h-20 p-2 overflow-hidden">
                                        <img
                                            className="object-cover h-14"
                                            src="/img/ui/victory.png"
                                            alt="trophy"
                                        />
                                    </div>
                                    <span>+200.000Đ</span>
                                </div>
                            </div>
                        </div>
                    )}
                    <div>
                        <Top4 users={users.slice(3, users.length)} />
                    </div>
                    <div className="flex justify-center gap-2 text-white p-2">
                        <button
                            onClick={() => onChangeTime(false)}
                            className={`p-1 rounded-lg ${
                                !isThisTime
                                    ? 'bg-primary-600'
                                    : 'bg-transparent text-black border'
                            }`}
                        >
                            Tháng trước
                        </button>
                        <button
                            onClick={() => onChangeTime(true)}
                            className={`p-1 rounded-lg ${
                                isThisTime
                                    ? 'bg-primary-600'
                                    : 'bg-transparent text-black'
                            }`}
                        >
                            Tháng này
                        </button>
                    </div>
                    <div>
                        <p className="text-center">
                            Cập nhật lần cuối:{' '}
                            {format(new Date(), 'HH:mm dd/MM/yyyy')}
                        </p>
                        <p className="text-center">
                            Xem Quy định tham gia chương trình Đua Top Hoàn Tiền{' '}
                            <Link
                                to={'/post/21'}
                                className="font-semibold underline text-primary-600"
                            >
                                TẠI ĐÂY
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopCashBackV2;
