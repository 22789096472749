import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import SearchInputV2 from '../../../components/Ecommerce/SearchInputV2';
import shopeApi from '../../../api/shopeeApi';
import SearchResultV2 from '../../../components/Ecommerce/SearchResultV2';
// import RecommendList from '../../../components/Ecommerce/RecommendList';
import FooterV2 from '../../../components/FooterV2';
import CashBackGuide from '../../../components/Ecommerce/CashBackGuide';
import FindButton from '../../../components/Ecommerce/FindButton';
import LongHouseePet from '../../../components/Ecommerce/LongHouseePet';
import AnswerQuestion from '../../../components/Q&A/Q&A';
import PolicyShopee from '../../../components/Ecommerce/PilicyShopee';

function ShopeeNew() {
    const [searchTerm, setSearchTerm] = useState('');
    const [productList, setProductList] = useState([]);
    // const [recommmendList, setRecommendList] = useState([]);
    const [isLoading, setisLoading] = useState(false);

    useEffect(() => {
        if (!searchTerm) return;
        setProductList([]);
        const fetchProductInfoByLink = async () => {
            setisLoading(true);
            try {
                const { data } = await shopeApi.getProductInfo(searchTerm);
                setProductList([data.productInfo]);
            } catch (error) {
                console.log(error);
                setProductList([]);
            }
            setisLoading(false);
        };
        fetchProductInfoByLink();
    }, [searchTerm]);

    // useEffect(() => {
    //     if (!productList.length) return;
    //     const fetchProductListByKeyword = async () => {
    //         setisLoading(true);
    //         try {
    //             const { data } = await shopeApi.getProductByKeyword(
    //                 productList[0].productName
    //             );
    //             setRecommendList(data.productList);
    //         } catch (error) {
    //             console.log(error);
    //             setRecommendList([]);
    //         }
    //         setisLoading(false);
    //     };
    //     fetchProductListByKeyword();
    // }, [productList]);
    return (
        <div className="h-dvh flex flex-col justify-between">
            <Header />
            <div className="pt-16 w-full md:w-[768px] lg:w-[1024px] mx-auto flex flex-col gap-2">
                <div>
                    <SearchInputV2
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        // setRecommendList={setRecommendList}
                        isLoading={isLoading}
                        platform={'shopee'}
                        titleTextColor={'text-primary-600'}
                        cashBackTitle={'Hoàn tiền đến 30.28%'}
                    />
                </div>
                {!searchTerm && <LongHouseePet platform={'Shopee'} />}
                {searchTerm && (
                    <SearchResultV2
                        isLoading={isLoading}
                        platform={'shopee'}
                        productList={productList}
                    />
                )}
                {/* {searchTerm && recommmendList.length > 0 && (
                    <RecommendList
                        productList={recommmendList}
                        platform={'shopee'}
                    />
                )} */}

                {!searchTerm && (
                    <>
                        <FindButton platform={'shopee'} />
                        <div className="p-2 bg-white">
                            <CashBackGuide platform={'shopee'} />
                        </div>
                        <PolicyShopee />
                    </>
                )}

                <AnswerQuestion postId={10} />
            </div>
            <FooterV2 />
        </div>
    );
}

export default ShopeeNew;
