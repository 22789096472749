import { faSadCry } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function ErrorPopup({ errorMsg, setModal, icon }) {
    return (
        <div className="animate-zoomIn w-4/5 md:w-2/3 lg:w-2/5 aspect-square flex flex-col justify-between items-center bg-white rounded-lg shadow-sm border gap-2 p-2">
            <div className="flex flex-col  justify-center items-center flex-1">
                {icon || (
                    <FontAwesomeIcon
                        icon={faSadCry}
                        className="text-5xl text-slate-800"
                    />
                )}
                <p className="p-4 text-center">{errorMsg || 'ERROR!!!'}</p>
            </div>
            <div className="w-full">
                <button
                    onClick={() => setModal(false)}
                    className="p-2 border w-full rounded-lg"
                >
                    Đóng
                </button>
            </div>
        </div>
    );
}

export default ErrorPopup;
