import Select from 'react-select';
import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import handleErrorAsync from '../../../utils/handleErrorAsync';
import shopeeLiveApi from '../../../api/shopeeLiveApi';
import Modal from '../../../components/Modal';
import PendingLive from './PendingLive';
import ErrorPopup from '../../../components/Modal/popup/ErrorPopup';

function OrderLive({ pendingLives, reloadPendingLive }) {
    const LIMIT_PRODUCT = 490;
    const [sessionSelected, setSessionSelected] = useState();
    const [link, setLink] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [kindOfModal, setKindOfModal] = useState(2);
    const [openModal, setOpenModal] = useState(false);
    const [validate, setvalidate] = useState({
        link: true,
        sessionSelected: true,
    });

    const linkRef = useRef();
    const options = pendingLives.map((el) => {
        return {
            value: el.id,
            label: `${el.title} ${
                el.liveproducts.length >= LIMIT_PRODUCT ? '(full)' : ''
            }`,
            isDisabled: el.liveproducts.length >= LIMIT_PRODUCT,
        };
    });
    const onSelectSession = (value) => {
        setvalidate((prev) => ({ ...prev, sessionSelected: true }));
        setSessionSelected(value.value);
    };
    const onAddLink = (e) => {
        setErrorMsg('');
        setvalidate((prev) => ({ ...prev, link: true }));
        setLink(e.target.value.trim());
    };

    const onPaste = (e) => {
        const value = e.clipboardData.getData('text');
        setErrorMsg('');
        setvalidate((prev) => ({ ...prev, link: true }));
        setLink(value.trim());
    };

    const checkValidate = () => {
        setvalidate({
            link: link ? true : false,
            sessionSelected: sessionSelected ? true : false,
        });
    };

    const onSubmit = async () => {
        checkValidate();
        if (!validate.link || !validate.sessionSelected) return;
        try {
            await shopeeLiveApi.addProductToLive(link, sessionSelected);
            await reloadPendingLive();
            setKindOfModal(2);
        } catch (error) {
            setKindOfModal(3);
            handleErrorAsync(error, setErrorMsg);
        }
        setOpenModal(true);
    };

    return (
        <div className="px-2 py-6 bg-white rounded-lg mt-6 shadow-lg border">
            <p className="font-bold">Đặt lịch LIVE</p>
            <span className="text-sm text-gray-500">
                Bạn thích mua cái gì? Tui Live cái đó
            </span>
            <div>
                <div className="flex flex-col gap-4">
                    <div className="relative">
                        <input
                            onBlur={checkValidate}
                            ref={linkRef}
                            onKeyUp={onAddLink}
                            onPaste={onPaste}
                            type="text"
                            className={`p-3 bg-slate-200 w-full rounded-lg pr-10 border focus-within:border-primary-400 ${
                                validate.link
                                    ? ''
                                    : 'outline-red-500 outline outline-[1px]'
                            }`}
                            placeholder="Nhập link sản phẩm"
                        />
                        {link && (
                            <FontAwesomeIcon
                                onClick={() => {
                                    setLink('');
                                    linkRef.current.value = '';
                                }}
                                icon={faXmarkCircle}
                                className="absolute top-1/2 p-2 right-0 -translate-y-1/2 text-gray-400 hover:bg-gray-100 rounded-full"
                            />
                        )}
                    </div>
                    <div className="h-12">
                        <Select
                            menuPlacement="auto"
                            onChange={onSelectSession}
                            options={options}
                            placeholder="Chọn phiên Live"
                            components={{
                                IndicatorSeparator: false,
                            }}
                            styles={{
                                control: (baseStyle, state) => ({
                                    ...baseStyle,
                                    height: '100%',
                                    outline: 'none',
                                    borderRadius: '8px',
                                    boxShadow: 'none',
                                    borderColor: validate.sessionSelected
                                        ? 'gray'
                                        : 'red',
                                }),
                                container: (baseStyle, state) => ({
                                    ...baseStyle,
                                    height: '100%',
                                    outline: 'none',
                                    border: 'none',
                                }),
                                option: (baseStyle, state) => ({
                                    ...baseStyle,
                                    backgroundColor:
                                        state.isSelected && '#ec4899',
                                    ':hover': {
                                        backgroundColor:
                                            !state.isSelected && '#fce7f3',
                                    },
                                }),
                            }}
                        />
                    </div>
                    <div className="flex justify-center items-center">
                        <button
                            onClick={onSubmit}
                            className="p-2 bg-primary-600 text-white w-1/2 rounded-md mt-4"
                        >
                            Đặt Lịch
                        </button>
                    </div>
                </div>
            </div>
            <Modal isOpen={openModal}>
                {kindOfModal === 2 && (
                    <PendingLive setOpenModal={setOpenModal} />
                )}
                {kindOfModal === 3 && (
                    <ErrorPopup setModal={setOpenModal} errorMsg={errorMsg} />
                )}
            </Modal>
        </div>
    );
}

export default OrderLive;
