import { faArrowRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { linkgroup } from './JoinGroupV2';

function MainMenu({ isOpen, setOpen }) {
    const [menu, setMenu] = useState([
        {
            id: 1,
            title: 'Tài khoản của tôi',
            hasChild: false,
            path: '/user-info',
        },
        {
            id: 2,
            title: 'Mua sắm hoàn tiền',
            hasChild: true,
            isOpen: false,
            childs: [
                {
                    title: 'Shopee',
                    path: '/shopee',
                },
                {
                    title: 'TikTok',
                    path: '/tiktok',
                },
                {
                    title: 'Lazada',
                    path: '/lazada',
                },
            ],
        },
        {
            id: 3,
            title: 'Danh mục tiện ích',
            hasChild: true,
            isOpen: false,
            childs: [
                {
                    title: 'Đua top hoàn tiền',
                    path: '/duatophoantien',
                },
                {
                    title: 'Đổi voucher',
                    path: '/doivoucher',
                },
                {
                    title: 'Thưởng thêm hoàn tiền',
                    path: '/bouns-cash',
                },
            ],
        },
        {
            id: 4,
            title: 'Shopee Xu',
            hasChild: false,
            path: 'https://longhousee-shopeexu.vercel.app/',
        },
        {
            id: 5,
            title: 'Mẹo săn sale',
            hasChild: false,
            path: '/post',
        },
        {
            id: 6,
            title: 'Liên hệ',
            hasChild: false,
            path: linkgroup.zaloLonghousee,
        },
    ]);

    const onOpen = (index) => {
        setMenu((prev) => {
            return prev.map((el) => ({
                ...el,
                isOpen: el.id === index ? !el.isOpen : el.isOpen,
            }));
        });
    };
    return (
        <div
            className={`fixed top-0 h-dvh bg-gray-800 bg-opacity-80 ${
                isOpen ? 'w-full' : 'w-0'
            }`}
        >
            <div
                className={`${
                    isOpen ? 'w-4/5' : 'w-0'
                } fixed top-0 h-dvh shadow-lg border rounded-tl-3xl bg-white z-50 right-0 overflow-hidden transition-all`}
            >
                <div className="flex gap-2 items-center justify-between h-16 font-semibold">
                    <div className="h-full flex items-center rounded-lg p-2">
                        <img
                            className="h-full object-cover"
                            src="/img/brand-logo/longhousee.png"
                            alt="logo-chietkhaupro"
                        />
                        <div className="font-lexend flex flex-col justify-center items-start text-left text-sm font-bold leading-4 text-[#fd6b64]">
                            <span className="px-1">SĂN SALE CÙNG</span>
                            <span className="px-1">LONGHOUSEE</span>
                        </div>
                    </div>
                    <div
                        className="text-xl h-full flex items-center px-3"
                        onClick={() => setOpen(false)}
                    >
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                </div>
                <div className="p-4">
                    {menu.map((el) => (
                        <div key={el.id} className="cursor-pointer text-xl">
                            {!el.hasChild && (
                                <Link
                                    className="py-1 block font-semibold"
                                    to={el.path}
                                >
                                    {el.title}
                                </Link>
                            )}
                            {el.hasChild && (
                                <div
                                    onClick={() => onOpen(el.id)}
                                    className="flex items-center gap-2 font-semibold"
                                >
                                    <span>{el.title}</span>
                                    <FontAwesomeIcon
                                        icon={faChevronDown}
                                        className="text-base"
                                    />
                                </div>
                            )}
                            {el.hasChild && el.isOpen && (
                                <div className="px-3">
                                    {el.childs.map((child) => (
                                        <Link
                                            className="pb-1 block"
                                            to={child.path}
                                            key={child.title}
                                        >
                                            <div>{child.title}</div>
                                        </Link>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MainMenu;
