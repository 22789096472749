import {
    faBars,
    faChevronLeft,
    faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Bell from './BellNoti';
import MainMenu from '../pages/Home/MainMenu';

function Header({ hasBackButton, redirectPath }) {
    const { isLogined, userInfo } = useSelector((state) => state.auth);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const navigate = useNavigate();
    const reloadPage = () => {
        navigate(0);
        window.scrollTo(0, 0);
    };
    const pathName = window.location.pathname;
    return (
        <header className="h-16 fixed top-0 left-0 right-0 w-full border-b shadow-sm bg-white z-50">
            <div className="h-full flex items-center justify-between py-2 px-4 md:w-[768px] lg:w-[1024px] mx-auto">
                {!hasBackButton && pathName !== '/' && (
                    <Link
                        to={'/'}
                        className="h-full flex gap-2 items-center font-semibold"
                    >
                        <div className="h-full flex items-center rounded-lg hover:bg-slate-100">
                            <img
                                className="h-full object-cover"
                                src="/img/brand-logo/longhousee.png"
                                alt="logo-chietkhaupro"
                            />
                            <div className="font-lexend flex flex-col justify-center items-start text-left text-sm font-bold leading-4 text-[#fd6b64]">
                                <span className="px-1">SĂN SALE CÙNG</span>
                                <span className="px-1">LONGHOUSEE</span>
                            </div>
                        </div>
                    </Link>
                )}
                {!hasBackButton && pathName === '/' && (
                    <div
                        onClick={reloadPage}
                        className="h-full flex gap-2 items-center font-semibold cursor-pointer"
                    >
                        <div className="h-full flex items-center rounded-lg hover:bg-slate-100">
                            <img
                                className="h-full object-cover"
                                src="/img/brand-logo/longhousee.png"
                                alt="logo-chietkhaupro"
                            />
                            <div className="font-lexend flex flex-col justify-center items-start text-left text-sm font-bold leading-4 text-[#fd6b64]">
                                <span className="px-1">SĂN SALE CÙNG</span>
                                <span className="px-1">LONGHOUSEE</span>
                            </div>
                        </div>
                    </div>
                )}

                {hasBackButton && (
                    <Link
                        to={redirectPath || '/'}
                        className="flex gap-1 justify-center items-center cursor-pointer hover:bg-slate-200 py-2 rounded-md"
                    >
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            className="text-xl"
                        />
                        <span>Trở lại</span>
                    </Link>
                )}

                <div className="h-2/3">
                    {isLogined && (
                        <div className="flex h-full justify-between items-center gap-3">
                            <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                className="text-2xl"
                            />
                            <Bell notiList={userInfo.notifications} />
                            <FontAwesomeIcon
                                onClick={() => setIsOpenMenu(true)}
                                icon={faBars}
                                className="text-2xl"
                            />
                        </div>
                    )}
                    {!isLogined && (
                        <div className="flex items-center w-full h-full bg-slate-950 py-4 px-2 text-white text-sm rounded-lg">
                            <Link to="/login">Đăng nhập</Link>
                        </div>
                    )}
                </div>
            </div>
            <MainMenu isOpen={isOpenMenu} setOpen={setIsOpenMenu} />
        </header>
    );
}

export default Header;
