import { faCircleNotch, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';

function SearchInputV2({
    searchTerm,
    setSearchTerm,
    isLoading,
    platform,
    setRecommendList,
    cashBackTitle,
    titleTextColor,
    borderInputColor,
}) {
    const inputRef = useRef();
    let typingTimer;
    const delayActionTime = 500;
    const handleTypingDone = (e) => {
        clearTimeout(typingTimer);
        if (e.target.value) {
            typingTimer = setTimeout(() => {
                setSearchTerm(e.target.value.trim());
            }, delayActionTime);
        } else {
            setSearchTerm('');
        }
    };

    const handleOnPaste = (e) => {
        const value = e.clipboardData.getData('text');
        setSearchTerm(value.trim());
    };

    const onButtonPase = async () => {
        const text = await navigator.clipboard.readText();
        inputRef.current.value = text;
        setSearchTerm(text);
    };

    const onClearInput = () => {
        setSearchTerm('');
        if (setRecommendList) {
            setRecommendList([]);
        }
        inputRef.current.value = '';
        inputRef.current.focus();
    };

    return (
        <div className="flex flex-col gap-4 w-full p-2 bg-white">
            <div className="text-center flex flex-col justify-center items-center bg-white">
                <div className="h-20 p-2 w-full flex items-center justify-center">
                    {platform === 'shopee' && (
                        <div className="h-full flex justify-center items-center p-2">
                            <img
                                src="img/ui/shopee_full.svg"
                                className="object-contain h-full"
                                alt="tiktok-logo"
                            />
                        </div>
                    )}
                    {platform === 'tiktok' && (
                        <div className="h-full flex justify-center items-center p-2">
                            <img
                                src="img/ui/tiktok_full.svg"
                                className="object-contain h-full"
                                alt="tiktok-logo"
                            />
                        </div>
                    )}
                    {platform === 'lazada' && (
                        <div className="h-full flex justify-center items-center p-2">
                            <img
                                src="img/ui/lazada_full.svg"
                                className="object-contain h-full"
                                alt="tiktok-logo"
                            />
                        </div>
                    )}
                </div>
                <p className={`font-semibold ${titleTextColor || ''}`}>
                    {cashBackTitle || 'Hoàn tiền đến 22.25%'}
                </p>
            </div>
            <div className="flex gap-1 justify-between rounded-lg shadow-lg bg-white">
                <div className="relative flex-1 w-full">
                    <input
                        ref={inputRef}
                        onPaste={handleOnPaste}
                        onKeyUp={handleTypingDone}
                        className={`bg-gray-200 h-full py-4 pr-24 pl-8 w-full rounded-lg placeholder:font-semibold placeholder:text-sm focus:shadow focus:shadow-primary-200 focus:outline-[1px] border ${
                            borderInputColor || 'border-primary-600'
                        }`}
                        type="text"
                        placeholder="Link sản phẩm"
                    />
                    <button
                        onClick={
                            !isLoading && !searchTerm
                                ? onButtonPase
                                : onClearInput
                        }
                        className={`absolute shadow-lg w-20 h-8  border top-1/2 right-2 -translate-y-1/2 flex items-center justify-center bg-white px-3 py-1 font-semibold rounded-lg text-sm ${
                            titleTextColor || 'text-primary-600'
                        }`}
                    >
                        {isLoading && (
                            <FontAwesomeIcon
                                icon={faCircleNotch}
                                className="animate-spin"
                            />
                        )}
                        {!isLoading && (!searchTerm ? 'Dán link' : 'Xoá')}
                    </button>
                    <FontAwesomeIcon
                        className="absolute top-1/2 left-2 -translate-y-1/2 text-slate-700"
                        icon={faLink}
                    />
                </div>
            </div>
        </div>
    );
}

export default SearchInputV2;
