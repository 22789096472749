import axiosClient from './axiosClient';

const authApi = {
    signUpStep1: (data) => {
        const baseURL = 'user/signup/step1';
        return axiosClient.post(baseURL, {
            email: data.email,
            ref: data.ref,
        });
    },
    signUpStep2: (data) => {
        const baseURL = 'user/signup/step2';
        return axiosClient.post(baseURL, {
            email: data.email,
            OTPCode: data.OTPCode,
        });
    },
    signUpStep3: (data) => {
        const baseURL = 'user/signup/step3';
        return axiosClient.post(baseURL, {
            email: data.email,
            password: data.password,
            oneTimeToken: data.oneTimeToken,
        });
    },
    login: (data) => {
        const baseURL = 'user/login';
        return axiosClient.post(baseURL, {
            email: data.email,
            password: data.password,
        });
    },
    getUserInfo() {
        const baseURL = 'user/get-me';
        return axiosClient.get(baseURL);
    },
    updateMe(data) {
        const baseURL = 'user/update-me';
        return axiosClient.post(baseURL, data);
    },
    getOTP() {
        const baseURL = 'user/otp';
        return axiosClient.get(baseURL);
    },
    verifyOTP(data) {
        const baseURL = 'user/otp';
        return axiosClient.post(baseURL, {
            OTPCode: data.OTPCode,
        });
    },
    setOtpNewEmail(data) {
        const baseURL = 'user/send-otp-new-email';
        return axiosClient.post(baseURL, {
            oneTimeToken: data.oneTimeToken,
            newEmail: data.newEmail,
        });
    },
    changeEmail: (data) => {
        const baseURL = 'user/change-email';
        return axiosClient.post(baseURL, {
            newEmail: data.newEmail,
            OTPCode: data.OTPCode,
            isMiddleware: true,
        });
    },
    changePassword: (data) => {
        const baseURL = 'user/change-password';
        return axiosClient.post(baseURL, {
            newPassword: data.newPassword,
            oneTimeToken: data.oneTimeToken,
        });
    },
    changeBankInfo: (data) => {
        const baseURL = 'user/change-bank-info';
        return axiosClient.post(baseURL, {
            bankInfo: data.bankInfo,
            oneTimeToken: data.oneTimeToken,
        });
    },
    saveBankInfoFirstTime: ({ bankId, bankAccountName, bankAccountNumber }) => {
        const baseURL = 'user/save_bank_first_time';
        return axiosClient.post(baseURL, {
            bankAccountName,
            bankAccountNumber,
            bankId,
        });
    },
    forgotPassword: (data) => {
        const baseURL = 'user/forgot-password';
        return axiosClient.post(baseURL, {
            email: data.email,
        });
    },
    verifyForgotPassword: (data) => {
        const baseURL = 'user/verify-reset-password';
        return axiosClient.post(baseURL, {
            email: data.email,
            OTPCode: data.OTPCode,
        });
    },
    resetPassword: (data) => {
        const baseURL = 'user/reset-password';
        return axiosClient.post(baseURL, {
            newPassword: data.newPassword,
            oneTimeToken: data.oneTimeToken,
        });
    },
    myOrders: (queryString) => {
        const baseURL = `user/my-orders?&sort=-purchaseTime&${
            queryString || ''
        }`;
        return axiosClient.get(baseURL);
    },
    myRefs: () => {
        const baseURL = `user/my-refs`;
        return axiosClient.get(baseURL);
    },
    myPendingCash: () => {
        const baseURL = `user/my-pending-cash`;
        return axiosClient.get(baseURL);
    },
    readAllNoti: () => {
        const baseURL = 'notification/read';
        return axiosClient.put(baseURL);
    },
    totalUser() {
        const baseURL = 'user/total_user';
        return axiosClient.get(baseURL);
    },
    topOfMonth(startTime = '', endTime = '') {
        const baseURL = `user/top_user_month?startTime=${startTime}&endTime=${endTime}`;
        return axiosClient.get(baseURL);
    },
    changeAvatar(formData) {
        const baseURL = 'user/change_avatar';
        return axiosClient.post(baseURL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
};

export default authApi;
