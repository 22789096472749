import FindOrder from '../../../components/Ecommerce/FindOrder';

function FIndTikTokOrder() {
    const logo = {
        url: '/img/brand-logo/logo-tiktok-shop-200.png',
        alt: 'tiktok-shop-logo',
    };
    return (
        <FindOrder
            logo={logo}
            title={'Nhập đơn hàng - Nhận chiết khấu'}
            description={'Áp dụng cho đơn hàng từ TikTok'}
            merchant={'tiktok'}
        />
    );
}

export default FIndTikTokOrder;
