import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faPiggyBank, faXmark, faZ } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

function JoinGroup({ onClose }) {
    return (
        <div className="relative p-2 w-4/5 md:w-1/4 animate-zoomIn lg:w-1/6 aspect-square flex flex-col justify-center items-center bg-white rounded-lg shadow-lg border gap-2">
            <FontAwesomeIcon
                className="animate-bounce text-4xl text-primary-600"
                icon={faPiggyBank}
            />
            <p className="text-center text-xl font-semibold">Tham gia group</p>
            <span className="text-center">
                Để săn sale và chém gió cùng Thợ Code nhaa
            </span>
            <div className="flex items-center justify-center gap-1 w-full">
                <FontAwesomeIcon className="text-blue-600" icon={faZ} />
                <Link
                    target="_blank"
                    className="underline text-ellipsis overflow-hidden whitespace-nowrap"
                    to={'https://zalo.me/g/hcxvof127'}
                >
                    zalo.me/g/hcxvof127
                </Link>
            </div>
            <div className="flex items-center justify-center gap-1 w-full">
                <FontAwesomeIcon className="text-blue-600" icon={faFacebook} />
                <Link
                    target="_blank"
                    className="underline text-ellipsis overflow-hidden whitespace-nowrap"
                    to={'https://www.facebook.com/groups/chietkhaupro'}
                >
                    facebook.com/groups/chietkhaupro
                </Link>
            </div>

            <span
                onClick={onClose}
                className="absolute top-0 right-0 w-8 aspect-square flex justify-center items-center text-gray-600 text-xl"
            >
                <FontAwesomeIcon
                    className="pointer-events-none"
                    icon={faXmark}
                />
            </span>
        </div>
    );
}

export default JoinGroup;
