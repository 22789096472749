import {
    faEnvelope,
    faPaperPlane,
    faZ,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { linkgroup } from '../pages/Home/JoinGroupV2';

function FooterV2() {
    return (
        <div className="bg-gradient-to-br from-[#fd6b64] to-[#ff8243] pt-6">
            <div className="text-center text-white">
                <div className="flex justify-center items-center">
                    <div className="w-20 aspect-square">
                        <img
                            src="img/brand-logo/longhousee.png"
                            alt="logolonghousee"
                        />
                    </div>
                    <div className="font-lexend flex flex-col justify-center items-center font-bold">
                        <span className="px-2">SĂN SALE CÙNG</span>
                        <span className="px-1">LONGHOUSEE</span>
                    </div>
                </div>
                <p className="font-semibold text-sm p-4">
                    Mua sắm thông minh, hoàn tiền cực đã
                </p>
            </div>
            <div className="py-2 px-2 mx-6 rounded-t-lg bg-white flex justify-between items-center gap-1 md:px-4">
                <div className="flex gap-1 justify-center items-center text-sm">
                    <div className="w-5 h-5 rounded-full bg-black text-white text-xs flex justify-center items-center">
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            className="text-xs"
                        />
                    </div>
                    <span className="font-semibold text-[8px] md:text-base">
                        longhousee@gmail.com
                    </span>
                </div>
                <div className="flex gap-1 justify-center items-center text-sm">
                    <div className="w-5 h-5 rounded-full bg-black text-white text-xs flex justify-center items-center">
                        <FontAwesomeIcon
                            icon={faPaperPlane}
                            className="text-xs"
                        />
                    </div>
                    <Link
                        to={linkgroup.telegram}
                        className="font-semibold text-[8px] md:text-base hover:underline"
                    >
                        {linkgroup.telegram.replace('https://', '')}
                    </Link>
                </div>
                <div className="flex gap-1 justify-center items-center text-sm">
                    <div className="w-5 h-5 rounded-full bg-black text-white text-xs flex justify-center items-center">
                        <FontAwesomeIcon icon={faZ} className="text-xs" />
                    </div>
                    <Link
                        to={linkgroup.zalo}
                        className="font-semibold text-[8px] md:text-base hover:underline"
                    >
                        {linkgroup.zalo.replace('https://', '')}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default FooterV2;
