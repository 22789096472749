import paymentApi from '../../api/paymentApi';

const withdrawHistoryLoader = async () => {
    try {
        const { data } = await paymentApi.myWithdrawRequest();
        return data.data.withdrawRequests;
    } catch (error) {
        console.log(error);
        return [];
    }
};

export default withdrawHistoryLoader;
