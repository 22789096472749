import { createSlice } from '@reduxjs/toolkit';
const refSlice = createSlice({
    name: 'ref',
    initialState: {
        ref: null,
    },
    reducers: {
        setRef(state, action) {
            state.ref = action.payload;
        },
    },
});

const refActions = refSlice.actions;
const refReducer = refSlice.reducer;

export { refActions };
export default refReducer;
