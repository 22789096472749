import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import redirectReducer from './redirectSlide';
import refReducer from './refSlice';
import popupReducer from './popupSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        redirect: redirectReducer,
        ref: refReducer,
        popup: popupReducer,
    },
});

export default store;
