import React, { useEffect, useRef, useState } from 'react';
import shopeApi from '../../../api/shopeeApi';
import Modal from '../../../components/Modal';
import RedirectPopup from '../../../components/Ecommerce/RedirectPopup';

const clickAffLink = (url) => {
    // setTimeout(() => {
    //     window.open(url, '_blank');
    // }, 100);
    window.location.href = url;
};

function VideoProductCard({ product }) {
    const COUNTDOWN_TIME = useRef(0.2); // Minutes
    const SHOPEE_VIDEO_URL = 'https://sv.shopee.vn/share-video/';
    const videoUrl = SHOPEE_VIDEO_URL + product.postId;
    const [onGetLink, setOnGetLink] = useState(false);
    const [clock, setClock] = useState(Date.now());
    const onSubmit = async () => {
        setOnGetLink(true);
        try {
            const { data } = await shopeApi.getLinkAff(videoUrl);
            const linkAff = data.linkAff.batchCustomLink[0].longLink;
            if (linkAff) {
                clickAffLink(linkAff);
            }
        } catch (error) {
            console.log(error);
        }
        setOnGetLink(false);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setClock(Date.now());
        }, [1000]);

        return () => {
            clearInterval(timer);
        };
    });

    return (
        <div className="text-sm bg-white border rounded-md overflow-hidden drop-shadow-md ">
            <div className="flex gap-2 bg-white border-b p-1">
                <img
                    className="w-14 h-14"
                    src={product.imageUrl}
                    alt="product"
                />
                <p className="line-clamp-3 text-ellipsis overflow-hidden h-full">
                    {product.productName}
                </p>
            </div>
            <div className="flex justify-end">
                {clock >=
                product.createTime + COUNTDOWN_TIME.current * 60 * 1000 ? (
                    <div>
                        <button
                            onClick={onSubmit}
                            className="h-full bg-primary-600 text-white p-2"
                        >
                            Mua ngay
                        </button>
                    </div>
                ) : (
                    <div className="flex items-center justify-between w-full p-2">
                        <div className="flex gap-1 items-center">
                            {/* <FontAwesomeIcon
                                icon={faCircleNotch}
                                className="animate-spin"
                            />
                            <span className="text-sm">
                                {formatDistance(
                                    product.createTime +
                                        COUNTDOWN_TIME.current * 60 * 1000,
                                    clock,
                                    {
                                        locale: vi,
                                        includeSeconds: true,
                                    }
                                )}
                            </span> */}
                        </div>
                        <div>
                            <span>Video sẽ được trả sau: </span>
                            {/* <span className="text-primary-600 font-semibold">
                                {format(
                                    product.createTime +
                                        COUNTDOWN_TIME.current * 60 * 1000,
                                    'HH:mm:ss'
                                )}
                            </span> */}
                            <span>2-5 phút</span>
                        </div>
                    </div>
                )}
            </div>
            <Modal isOpen={onGetLink}>
                <RedirectPopup platform={'Shopee'} />
            </Modal>
        </div>
    );
}

export default VideoProductCard;
