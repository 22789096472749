import React from 'react';
import { Link } from 'react-router-dom';
import { linkgroup } from '../../pages/Home/JoinGroupV2';

function JoinZalo({ linkZalo, title }) {
    const ZALO_URL = linkZalo || linkgroup.zalo;
    return (
        <div className="fixed z-10 right-2 bottom-6 animate-shake flex flex-col items-end gap-1">
            <div>
                <span className="text-sm bg-gray-200 px-1 rounded-md drop-shadow-md">
                    {title || 'Zô nhóm với tui nè'}
                </span>
            </div>
            <Link to={ZALO_URL} target="_blank">
                <img
                    className="w-10 h-10 object-cover drop-shadow-sm"
                    src="/img/brand-logo/logo-zalo.webp"
                    alt="zalo"
                />
            </Link>
        </div>
    );
}

export default JoinZalo;
