import { redirect } from 'react-router-dom';

const { default: authApi } = require('../../api/authApi');

const checkLiveUsers = async () => {
    try {
        const { data } = await authApi.getUserInfo();
        if (!data.data.user.isLiveUser) return redirect('/');
        return null;
    } catch (error) {
        return redirect('/');
    }
};

export default checkLiveUsers;
