import { faGem } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const rankName = (rank, textOnly) => {
    if (textOnly) {
        let rankName = 'Tập sự';
        switch (rank) {
            case 0:
                break;
            case 1:
                break;
            case 2:
                rankName = 'Tân binh';
                break;
            case 3:
                rankName = 'Chuyên gia';
                break;
            case 4:
                rankName = 'Cao thủ';
                break;
            case 5:
                rankName = 'Chiến thần';
                break;
            case 6:
                rankName = 'Huyền thoại';
                break;
            case 7:
                rankName = 'Bất hủ';
                break;
            case 888:
                rankName = 'Admin';
                break;
            default:
                break;
        }
        return rankName;
    }
    let rankTag = (
        <span className="px-1 rounded bg-gradient-to-tr from-lime-300 to-green-600 text-white">
            Tập sự
        </span>
    );
    if (!rank) return rankTag;
    switch (rank) {
        case 0:
            break;
        case 1:
            break;
        case 2:
            rankTag = (
                <span className="px-1 rounded bg-gradient-to-tr from-blue-300 to-blue-500 text-white">
                    Tân binh
                </span>
            );
            break;
        case 3:
            rankTag = (
                <div className="px-2 rounded inline-block bg-gradient-to-tr from-indigo-300 to-indigo-600 text-white relative overflow-hidden">
                    <span>Chuyên gia</span>
                    <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent  animate-hightLight"></span>
                </div>
            );
            break;
        case 4:
            rankTag = (
                <div className="px-2 rounded inline-block bg-gradient-to-tr from-violet-300 to-violet-700 text-white relative overflow-hidden">
                    <span>Cao thủ</span>
                    <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent  animate-hightLight"></span>
                </div>
            );
            break;
        case 5:
            rankTag = (
                <div className="px-1 rounded inline-block bg-gradient-to-tr from-pink-300 to-pink-600 text-white relative overflow-hidden">
                    <span>Chiến thần</span>
                    <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent  animate-hightLight"></span>
                </div>
            );
            break;
        case 6:
            rankTag = (
                <div className="px-1 rounded inline-block bg-gradient-to-tr from-orange-300 to-orange-500 text-white relative overflow-hidden">
                    <span>Huyền thoại</span>
                    <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent  animate-hightLight"></span>
                </div>
            );
            break;
        case 7:
            rankTag = (
                <div className="px-1 rounded inline-block bg-gradient-to-tr from-amber-300 to-amber-500 text-white relative overflow-hidden">
                    <span>Bất hủ</span>
                    <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent  animate-hightLight"></span>
                </div>
            );
            break;
        case 999:
            rankTag = (
                <div className="px-1 rounded inline-block bg-gradient-to-tr from-red-300 via-indigo-500 to-pink-500 text-white relative overflow-hidden">
                    <span>May mắn</span>
                    <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent  animate-hightLight"></span>
                </div>
            );
            break;
        case 888:
            rankTag = (
                <div className="px-1 rounded inline-block bg-gradient-to-tr from-red-300 via-indigo-500 to-pink-500 text-white relative overflow-hidden">
                    <span>
                        Admin <FontAwesomeIcon icon={faGem} />
                    </span>
                    <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent  animate-hightLight"></span>
                </div>
            );
            break;
        default:
            break;
    }

    return rankTag;
};

export default rankName;
