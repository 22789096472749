import FindOrder from '../../../components/Ecommerce/FindOrder';

function FindShopeeOrder() {
    const logo = {
        url: '/img/brand-logo/logo-shopee-200.png',
        alt: 'shopee-logo',
    };

    return (
        <FindOrder
            logo={logo}
            merchant={'shopee'}
            description={'Áp dụng cho thành viên Chiết Khấu Pro Shopee Live'}
            title={'Nhập đơn Shopee Live của bạn'}
        />
    );
}

export default FindShopeeOrder;
