import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import postApi from '../../api/postApi';

const fontSizeArr = [
    '8px',
    '9px',
    '10px',
    '12px',
    '14px',
    '16px',
    '20px',
    '24px',
    '32px',
    '42px',
    '54px',
    '68px',
    '84px',
    '98px',
];
const Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        [{ size: fontSizeArr }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
        ],
        ['link'],
        ['clean'],
    ],
};

const formats = [
    'header',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
];
function PostEditor() {
    const { postid } = useParams();
    console.log(postid);
    const [title, setTitle] = useState('');
    const [summary, setSummary] = useState('');
    const [content, setContent] = useState();
    const [file, setFile] = useState();
    const navigate = useNavigate();

    const onSubmit = async () => {
        if (!title || !summary || !content || !file) return;
        const formData = new FormData();
        formData.append('title', title);
        formData.append('summary', summary);
        formData.append('content', content);
        formData.append('image', file);
        formData.append('category', 'guide');

        try {
            await postApi.newPost(formData);
            navigate('/post');
        } catch (error) {}
    };

    const onUpdatePost = async () => {
        console.log('runn');
        const formData = new FormData();
        formData.append('title', title);
        formData.append('summary', summary);
        formData.append('content', content);
        if (file) formData.append('image', file);
        formData.append('postId', postid);

        try {
            await postApi.updatePost(formData);
            navigate('/post');
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!postid) return;
        const fetchPost = async () => {
            try {
                const { data } = await postApi.getGuidePost(
                    `category=guide&postId=${postid}`
                );
                const post = data.post[0];
                setTitle(post.title);
                setSummary(post.summary);
                setContent(post.content);
            } catch (error) {
                console.log(error);
            }
        };
        fetchPost();
    }, [postid]);
    return (
        <div className="p-4 flex flex-col gap-2 h-dvh">
            <h3 className="font-bold text-2xl">Tạo bài viết</h3>
            <input
                type="text"
                className="p-2 rounded-md border"
                name="title"
                id="title"
                placeholder="Tiêu đề"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />
            <input
                type="text"
                className="p-2 rounded-md border"
                name="sumary"
                id="sumary"
                placeholder="Mô tả"
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
            />
            <input
                onChange={(e) => setFile(e.target.files[0])}
                type="file"
                className="p-2 rounded-md border"
                name="sumary"
                id="sumary"
                placeholder="Ảnh bìa"
            />
            <div className="flex-1 h-full">
                <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={content}
                    onChange={(e) => setContent(e)}
                    className="h-full overflow-auto"
                />
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => {
                        if (postid) return onUpdatePost();
                        onSubmit();
                    }}
                    className="p-2 bg-primary-500 min-w-[80px] text-white rounded-md"
                >
                    {postid ? 'Cập Nhật' : 'Tạo'}
                </button>
            </div>
        </div>
    );
}

export default PostEditor;
