import React from 'react';
import FindOrder from '../../../components/Ecommerce/FindOrder';

function FindShopeeVideo() {
    const logo = {
        url: '/img/brand-logo/logo-shopee-200.png',
        alt: 'shopee-shop-logo',
    };
    return (
        <FindOrder
            logo={logo}
            title={'Nhập đơn hàng Shopee Video của bạn'}
            description={
                'Sau 48 giờ đặt hàng thành công bạn mới có thể sử dụng tính năng này. Trường hợp không tìm thấy đơn là do đơn hoàn, hủy, đơn mua từ video KOL hoặc bị nghi ngờ gian lận, vi phạm các điều khoản hoàn tiền của sàn.'
            }
            merchant={'shopee'}
        />
    );
}

export default FindShopeeVideo;
