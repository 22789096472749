import axiosClient from './axiosClient';

const tiktokApi = {
    getProductInfo: (productUrl) => {
        const baseURL = 'tiktok/get-product-info';
        return axiosClient.post(baseURL, {
            productUrl,
        });
    },
    addToShowCase: (productId) => {
        const baseURL = 'tiktok/add-to-showcase';
        return axiosClient.post(baseURL, {
            productId,
        });
    },
    findOrder: (orderId) => {
        const baseURL = 'tiktok/orders/find';
        return axiosClient.post(baseURL, {
            orderId,
        });
    },
};

export default tiktokApi;
