import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function RedirectPopup({ platform }) {
    return (
        <div className="w-4/5 bg-white aspect-square rounded-lg border flex flex-col gap-2 justify-center items-center">
            <FontAwesomeIcon
                className="flex text-5xl text-primary-600 animate-spin"
                icon={faCircleNotch}
            />
            <span className="text-center">
                {`Đợi tui xíu, đang chuyển tới ${platform || '...'}`}{' '}
            </span>
        </div>
    );
}

export default RedirectPopup;
