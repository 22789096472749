import { createSlice } from '@reduxjs/toolkit';
const redirectSlide = createSlice({
    name: 'redirect',
    initialState: {
        isRedirecting: false,
        platform: '',
        productInfo: null,
    },
    reducers: {
        startRedirect(state, action) {
            state.isRedirecting = true;
            state.platform = action.payload.platform;
            state.productInfo = action.payload.productInfo;
        },
        endRedirect(state, action) {
            state.isLogined = false;
            state.platform = '';
            state.productInfo = null;
        },
    },
});

const redirectActions = redirectSlide.actions;
const redirectReducer = redirectSlide.reducer;

export { redirectActions };
export default redirectReducer;
