import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function CommentBox({ value, setValue, onSubmit, size, autoFocus }) {
    return (
        <div className=" bg-zinc-100 flex flex-col  border border-black overflow-hidden text-base">
            <input
                value={value}
                onChange={(e) => setValue(e.target.value)}
                autoFocus={autoFocus}
                type="text"
                className="bg-zinc-100 p-2 py-3 break-before-auto"
                placeholder="Viết bình luận"
            />
            <div className="flex justify-end w-full p-1">
                <FontAwesomeIcon
                    onClick={onSubmit}
                    icon={faPaperPlane}
                    className="text-black hover:bg-slate-300 p-1 rounded-lg text-xl cursor-pointer"
                />
            </div>
        </div>
    );
}

export default CommentBox;
