import { Suspense, lazy, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Home from './pages/Home/Home';
import { getUserInfoByToken } from './store/authSlice';
import checkLogined from './utils/loader/checkLogined';
import changeBankInfoLoader from './utils/loader/changeBankInfoLoader';
import withdrawLoader from './utils/loader/withdrawLoader';
import withdrawHistoryLoader from './utils/loader/withdrawHistoryLoader';
import protectUser from './utils/loader/protectUser';
import FIndTikTokOrder from './pages/Merchant/TikTok/FIndTikTokOrder';
import ErrorDefault from './pages/Error/Error';
import Notification from './pages/Notification';
import FindShopeeOrder from './pages/Merchant/ShopeeLive/FindShopeeOrder';
import checkLiveUsers from './utils/loader/checkLiveUsers';
import ShopeeLiveV2 from './pages/Merchant/ShopeeLive/ShopeeLiveV2';
import Ref from './pages/User/UserInfo/Ref';
import RejectOrderPost from './pages/Merchant/Lazada/RejectOrderPost';
import LuckyWheel from './pages/LuckyWheel/LuckyWheel';
// import ShopeeVideoV2 from './pages/Merchant/ShopeeVideo/ShopeeVideoV2';
import ShopeeVideoMultiLink from './pages/Merchant/ShopeeVideo/MultipleLink/MultipleLink';
import ShopeeLiveGuide from './pages/Merchant/Shopee/ShopeeLiveGuide';
import ShopeeXu from './pages/Merchant/ShopeeXu/ShopeeXu';
import Post from './pages/Post/Post';
import PostMain from './pages/Post/PostMain';
import PostPage from './pages/Post/PostPage';
import PostEditor from './pages/Post/PostEditor';
import VideoList from './pages/Post/VideoList';
import FindShopeeVideo from './pages/Merchant/Shopee/FindShopeeVideo';
import ShopeeNew from './pages/Merchant/Shopee/ShopeeNew';
import TikTokNew from './pages/Merchant/TikTok/TikTokNew';
import LazadaNew from './pages/Merchant/Lazada/LazadaNew';
import TopCashBackV2 from './pages/Event/TopCashBackV2';

const Category = lazy(() => import('./pages/Merchant/Shopee/Category'));
const Signup = lazy(() => import('./pages/Auth/Signup/Signup'));
const Login = lazy(() => import('./pages/Auth/Login/Login'));
const ForgotPW = lazy(() => import('./pages/Auth/ForgotPW/ForgotPW'));
const About = lazy(() => import('./pages/About'));

const User = lazy(() => import('./pages/User'));
const UserInfo = lazy(() => import('./pages/User/UserInfo/UserInfo'));
const ChangeEmail = lazy(() => import('./pages/User/UserInfo/ChangeEmail'));
const ChangePassword = lazy(() =>
    import('./pages/User/UserInfo/ChangePassword')
);
const ChangeUserName = lazy(() =>
    import('./pages/User/UserInfo/ChangeName/ChangeUserName')
);
const ChangeBankInfo = lazy(() =>
    import('./pages/User/UserInfo/ChangeBankInfo')
);
const Order = lazy(() => import('./pages/User/Order'));
const Withdraw = lazy(() => import('./pages/User/Withdraw'));
const WithdrawHistory = lazy(() => import('./pages/User/WithdrawHistory'));
const Redirect = lazy(() => import('./pages/Redirect/Redirect'));
// const Lazada = lazy(() => import('./pages/Merchant/Lazada/Lazada'));
// const TikTok = lazy(() => import('./pages/Merchant/TikTok'));
const Help = lazy(() => import('./pages/Help/index'));
const HelpCenter = lazy(() => import('./pages/Help/HelpCenter'));
const OrderHelperMain = lazy(() =>
    import('./pages/Help/OrderHelper/OrderHelperMain')
);
const OrderHelper = lazy(() => import('./pages/Help/OrderHelper/OrderHelper'));
const LostOrder = lazy(() => import('./pages/Help/OrderHelper/LostOrder'));
const CommissionIncorrect = lazy(() =>
    import('./pages/Help/OrderHelper/CommissionIncorrect')
);
const Guide = lazy(() => import('./pages/Help/Guide'));
const GuideMain = lazy(() => import('./pages/Help/Guide/GuideMain'));
const GetDisCount = lazy(() => import('./pages/Help/Guide/GetDiscount'));
const HowToChietKhauProWork = lazy(() =>
    import('./pages/Help/Guide/HowToWork')
);
const HowToWithdraw = lazy(() => import('./pages/Help/Guide/HowToWithDraw'));

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('access_token')) {
            dispatch(getUserInfoByToken());
        }
    }, [dispatch]);
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Home />,
            errorElement: <ErrorDefault />,
        },
        {
            path: 'top_cash_back',
            element: <TopCashBackV2 />,
            errorElement: <ErrorDefault />,
        },
        {
            path: '/error',
            element: <ErrorDefault />,
        },
        {
            path: '/signup',
            element: (
                <Suspense>
                    <Signup />
                </Suspense>
            ),
            loader: checkLogined,
            errorElement: <ErrorDefault />,
        },
        {
            path: '/login',
            element: (
                <Suspense>
                    <Login />
                </Suspense>
            ),
            loader: checkLogined,
            errorElement: <ErrorDefault />,
        },
        {
            path: '/forgot-password',
            element: (
                <Suspense>
                    <ForgotPW />
                </Suspense>
            ),
            errorElement: <ErrorDefault />,
        },
        {
            path: '/user-info',
            loader: protectUser,
            element: (
                <Suspense>
                    <User />
                </Suspense>
            ),
            errorElement: <ErrorDefault />,
            children: [
                {
                    index: true,
                    element: <UserInfo />,
                },
                {
                    path: 'change-user-name',
                    element: <ChangeUserName />,
                },
                {
                    path: 'change-email',
                    element: <ChangeEmail />,
                },
                {
                    path: 'change-password',
                    element: <ChangePassword />,
                },
                {
                    path: 'ref',
                    element: <Ref />,
                },
                {
                    path: 'change-bank-info',
                    element: <ChangeBankInfo />,
                    loader: changeBankInfoLoader,
                },
                {
                    path: 'withdraw',
                    children: [
                        {
                            index: true,
                            element: (
                                <Suspense>
                                    <Withdraw />
                                </Suspense>
                            ),
                            loader: withdrawLoader,
                        },
                        {
                            path: 'history',
                            element: (
                                <Suspense>
                                    <WithdrawHistory />
                                </Suspense>
                            ),
                            loader: withdrawHistoryLoader,
                        },
                    ],
                },
                {
                    path: 'order',
                    element: <Order />,
                    errorElement: <ErrorDefault />,
                },
            ],
        },

        {
            path: '/shopee',
            children: [
                {
                    index: true,
                    element: <ShopeeNew />,
                    errorElement: <ErrorDefault />,
                },
                {
                    path: ':category',
                    element: (
                        <Suspense>
                            <Category />
                        </Suspense>
                    ),
                    errorElement: <ErrorDefault />,
                },
                {
                    path: 'huongdanshopeelive',
                    element: <ShopeeLiveGuide />,
                },
                {
                    path: 'find',
                    element: <FindShopeeVideo />,
                },
            ],
        },
        {
            path: 'vongquayshopee',
            element: <ShopeeXu />,
        },
        {
            path: '/lazada',
            children: [
                {
                    index: true,
                    element: <LazadaNew />,
                },
                {
                    path: 'reject-order',
                    element: <RejectOrderPost />,
                },
            ],
            errorElement: <ErrorDefault />,
        },
        {
            path: '/tiktok',

            children: [
                {
                    index: true,
                    element: <TikTokNew />,
                    errorElement: <ErrorDefault />,
                },
                {
                    path: 'find',
                    element: <FIndTikTokOrder />,
                    errorElement: <ErrorDefault />,
                },
            ],
        },
        {
            path: 'shopeelive',
            children: [
                {
                    index: true,
                    element: <ShopeeLiveV2 />,
                },
                {
                    path: 'find',
                    element: <FindShopeeOrder />,
                    loader: checkLiveUsers,
                },
                {
                    path: 'v2',
                    element: <ShopeeLiveV2 />,
                },
            ],
        },
        {
            path: 'vid',
            element: <ShopeeVideoMultiLink />,
            errorElement: <ErrorDefault />,
            loader: protectUser,
        },
        {
            path: 'shopeevideo',
            element: <ShopeeVideoMultiLink isFake={true} />,
            errorElement: <ErrorDefault />,
            loader: protectUser,
        },
        {
            path: '/redirect',
            element: (
                <Suspense>
                    <Redirect />
                </Suspense>
            ),
        },
        {
            path: '/help',
            element: (
                <Suspense>
                    <Help />
                </Suspense>
            ),
            errorElement: <ErrorDefault />,
            children: [
                {
                    index: true,
                    element: <HelpCenter />,
                },
                {
                    path: 'order-helper',
                    element: (
                        <Suspense>
                            <OrderHelperMain />
                        </Suspense>
                    ),
                    children: [
                        {
                            element: <OrderHelper />,
                            index: true,
                        },
                        {
                            path: 'lost',
                            element: <LostOrder />,
                        },
                        {
                            path: 'incorrect-commission',
                            element: <CommissionIncorrect />,
                        },
                    ],
                },
                {
                    path: 'guide',
                    element: (
                        <Suspense>
                            <GuideMain />
                        </Suspense>
                    ),
                    children: [
                        {
                            index: true,
                            element: <Guide />,
                        },
                        {
                            element: <GetDisCount />,
                            path: 'how-to-get-discount',
                        },
                        {
                            element: <HowToChietKhauProWork />,
                            path: 'how-to-work',
                        },
                        {
                            element: <HowToWithdraw />,
                            path: 'how-to-withdraw',
                        },
                    ],
                },
            ],
        },
        {
            path: 'about',
            element: (
                <Suspense>
                    <About />
                </Suspense>
            ),
            errorElement: <ErrorDefault />,
        },
        {
            path: 'notification',
            element: <Notification />,
            errorElement: <ErrorDefault />,
        },
        {
            path: 'lucky-wheel',
            element: <LuckyWheel />,
            loader: protectUser,
        },
        {
            path: 'post',
            element: <Post />,
            children: [
                {
                    path: ':postid',
                    element: <PostPage />,
                },
                {
                    index: true,
                    element: <PostMain />,
                },
                {
                    path: 'post_editor',
                    element: <PostEditor />,
                },
                {
                    path: 'post_editor/:postid',
                    element: <PostEditor />,
                },
                {
                    path: 'videolist',
                    element: <VideoList />,
                },
            ],
        },
    ]);
    return <RouterProvider router={router} />;
}

export default App;
