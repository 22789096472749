import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch } from 'react-redux';
import { popupActions } from '../../../store/popupSlice';
// import JoinGroupV2 from '../../../pages/Home/JoinGroupV2';
import { Link } from 'react-router-dom';

function GoToGuide() {
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(popupActions.closeGuide());
    };
    return (
        <div className="relative w-4/5 p-2 md:w-2/3 animate-zoomIn lg:w-1/3 flex flex-col justify-center items-center bg-white rounded-lg shadow-lg border gap-2">
            <div className="w-full">
                {/* <Guide /> */}
                {/* <h3 className="text-center font-semibold text-xl">
                    Tham gia nhóm cùng tui nha
                </h3>
                <JoinGroupV2 hideFb={true} /> */}
                <Link to={'post/27'} className="flex flex-col gap-2">
                    <img src="img/ui/popup_banner.png" alt="forgetLink" />
                    {/* <div className="flex justify-center">
                        <button className="p-2 bg-primary-600 text-white rounded-md">
                            Xem ngay
                        </button>
                    </div> */}
                </Link>
            </div>
            <div
                onClick={onClose}
                className="absolute top-full right-1/2 translate-x-1/2 text-2xl mt-2 hover:text-gray-400"
            >
                <FontAwesomeIcon
                    icon={faXmark}
                    className="pointer-events-none"
                />
            </div>
        </div>
    );
}

export default GoToGuide;
