import axiosClient from './axiosClient';

const lazadaApi = {
    getProductInfo: (link) => {
        const baseURL = 'lazada/product-commission';
        return axiosClient.post(baseURL, {
            link,
        });
    },
    getProductByKeyword: (keyword, sortType) => {
        const baseURL = 'lazada/get-product-by-keyword';
        return axiosClient.post(baseURL, {
            keyword,
        });
    },
    getRecommendProductList: () => {
        const baseURL = 'lazada/recommend-product-list';
        return axiosClient.get(baseURL);
    },
    getLinkAff: (itemId, skuId, offerId) => {
        const baseURL = 'lazada/get-link';
        return axiosClient.post(baseURL, {
            itemId,
            skuId,
            offerId,
        });
    },
};

export default lazadaApi;
