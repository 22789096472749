import React from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { linkgroup } from './JoinGroupV2';

function Social() {
    return (
        <div className="bg-white p-1 flex flex-col gap-3">
            <div className="flex items-center gap-2">
                <div className="w-10 aspect-square bg-[#f1582c] rounded-full p-2">
                    <SVG
                        src="img/ui/plus.svg"
                        width={'auto'}
                        height={'auto'}
                        fill="white"
                    />
                </div>
                <div className="flex gap-1">
                    <span className="font-bold text-[1.1rem]">
                        CÁC KÊNH SĂN MÃ
                    </span>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-2 p-1">
                <Link
                    to={linkgroup.facebook}
                    target="_blank"
                    className="flex flex-col justify-center items-center bg-slate-200 rounded-lg px-1 py-3"
                >
                    <div className="flex flex-col justify-center items-center">
                        <div className="w-12 h-12 md:w-16">
                            <SVG
                                src="img/ui/facebook.svg"
                                width={'auto'}
                                height={'auto'}
                            />
                        </div>
                        <span className="font-semibold text-sm">Facebook</span>
                    </div>
                    <span className="text-[7px] md:text-xs bg-white px-1 rounded-lg text-center">
                        Cập nhật tin tức
                    </span>
                </Link>
                <Link
                    to={linkgroup.zalo}
                    target="_blank"
                    className="flex flex-col justify-center items-center bg-slate-200 rounded-lg px-1 py-3"
                >
                    <div className="flex flex-col justify-center items-center">
                        <div className="w-12 h-12 md:w-16">
                            <SVG
                                src="img/ui/zalo.svg"
                                width={'auto'}
                                height={'auto'}
                            />
                        </div>
                        <span className="font-semibold text-sm">Zalo</span>
                    </div>
                    <span className="text-[7px] md:text-xs bg-white px-1 rounded-lg text-center">
                        Cập nhật mã giảm giá
                    </span>
                </Link>
                <Link
                    to={linkgroup.telegram}
                    target="_blank"
                    className="flex flex-col justify-center items-center bg-slate-200 rounded-lg px-1 py-3"
                >
                    <div className="flex flex-col justify-center items-center">
                        <div className="w-12 h-12 md:w-16">
                            <SVG
                                src="img/ui/telegram.svg"
                                width={'auto'}
                                height={'auto'}
                            />
                        </div>
                        <span className="font-semibold text-sm">Telegram</span>
                    </div>
                    <span className="text-[7px] md:text-xs bg-white px-1 rounded-lg text-center">
                        Cập nhật mã giảm giá nhanh nhất
                    </span>
                </Link>
            </div>
        </div>
    );
}

export default Social;
