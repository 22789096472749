import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import postApi from '../../api/postApi';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

function PostMain() {
    const [posts, setPost] = useState([]);
    const { isLogined, userInfo } = useSelector((state) => state.auth);
    const fetchPost = async () => {
        try {
            const { data } = await postApi.getGuidePost(
                'category=guide&status=2&sort=-createTime'
            );
            setPost(data.post);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchPost();
    }, []);
    return (
        <div className="p-4">
            <Header />
            <div className="flex flex-col justify-between h-full md:w-[768px] lg:w-[1024px] md:mx-auto pt-16">
                <h1 className="font-bold text-xl mb-6">
                    Các bài viết của LongHousee
                </h1>
                <Link
                    to={'videolist'}
                    className="p-4 bg-white mb-4 rounded-md shadow-lg"
                >
                    <div className="">
                        <img
                            src="https://api.longhousee.com/img/post-thumbs/post-1716535988001.jpeg"
                            alt="banner"
                            className="w-full object-cover"
                        />
                    </div>
                    <p className="font-semibold text-sm">
                        TỔNG HỢP CÁC SẢN PHẨM ÁP MÃ ĐỘC QUYỀN LONG HOUSEE
                    </p>
                </Link>
                <div className="grid grid-cols-2 gap-1 gap-y-2">
                    {posts.map((post) => (
                        <Link
                            key={post.postId}
                            to={'' + post.postId}
                            className="rounded-lg overflow-hidden bg-white shadow-lg hover:-translate-y-1 cursor-pointer relative"
                        >
                            <div className="p-2 rounded-lg overflow-hidden">
                                <img
                                    src={`${process.env.REACT_APP_API_PUBLIC_URL}/img/post-thumbs/${post.imagePath}`}
                                    className="h-full w-full object-cover"
                                    alt="banner"
                                />
                            </div>
                            <div className="p-2">
                                <h3 className="font-semibold line-clamp-1">
                                    {post.title}
                                </h3>
                                <p className="line-clamp-2 text-sm text-gray-600">
                                    {post.summary}
                                </p>
                                <div className="flex gap-1 justify-end text-xs italic text-gray-500">
                                    <span>Ngày tạo:</span>
                                    <span>
                                        {format(
                                            post.createTime,
                                            'dd/MM/yyy HH:mm'
                                        )}
                                    </span>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
            {isLogined && userInfo.role === 'admin' && (
                <Link
                    to={'post_editor'}
                    className="absolute bottom-4 right-4 flex justify-center items-center text-4xl"
                >
                    <FontAwesomeIcon icon={faPlusCircle} />
                </Link>
            )}
        </div>
    );
}

export default PostMain;
