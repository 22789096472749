import { useState } from 'react';
import tiktokApi from '../../api/tiktokApi';
import Header from '../Header';
import Input from '../Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import ErrorPopup from '../Modal/popup/ErrorPopup';
import SuccessPopup from '../Modal/popup/SuccessPopup';
import Modal from '../Modal';
import handleErrorAsync from '../../utils/handleErrorAsync';
import shopeApi from '../../api/shopeeApi';

function FindOrder({ logo, title, description, merchant }) {
    const [errorMsg, setErrorMsg] = useState('');
    const [input, setInput] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [modalType, setModalType] = useState(2);
    const [openModal, setOpenModal] = useState(false);

    const onFindOrder = async () => {
        if (!input) return;
        setisLoading(true);
        setModalType(1);
        try {
            switch (merchant) {
                case 'tiktok':
                    await tiktokApi.findOrder(input);
                    break;
                case 'shopee':
                    await shopeApi.findOrder(input);
                    break;
                default:
                    break;
            }
            setModalType(2);
        } catch (error) {
            console.log(error);
            setModalType(3);
            handleErrorAsync(error, setErrorMsg);
        }
        setisLoading(false);
        setOpenModal(true);
    };

    return (
        <div className="h-[100dvh] flex flex-col justify-between">
            <Header />
            <div className="h-full flex flex-col justify-center items-center p-4 gap-10">
                {logo && (
                    <div className="w-20">
                        <img
                            className="object-cover"
                            src={logo.url}
                            alt={logo.alt}
                        />
                    </div>
                )}
                <div className="w-full">
                    <p className="font-semibold text-xl">
                        {title || ' Nhập đơn hàng của bạn'}
                    </p>
                    <span className="text-sm">
                        {description ||
                            ' Áp dụng cho thành viên Chiết Khấu Pro'}
                    </span>
                </div>
                <div className="w-full flex gap-1">
                    <div className="w-full">
                        <Input
                            setInput={setInput}
                            isClearable={true}
                            type="text"
                            className="w-full p-3 border-b-[1px] border-gray-500 bg-transparent"
                            placeholder="Nhập mã đơn hàng"
                            input={input}
                        />
                    </div>
                    <button
                        disabled={isLoading}
                        onClick={onFindOrder}
                        className="h-full min-w-[80px] whitespace-nowrap bg-primary-600 text-white rounded-lg py-1 px-2 disabled:opacity-60"
                    >
                        {isLoading ? (
                            <FontAwesomeIcon
                                icon={faCircleNotch}
                                className="animate-spin"
                            />
                        ) : (
                            'Tìm đơn'
                        )}
                    </button>
                </div>
            </div>
            {/* <div className="p-2">
                <Footer />
            </div> */}
            <Modal isOpen={openModal}>
                {modalType === 3 && (
                    <ErrorPopup setModal={setOpenModal} errorMsg={errorMsg} />
                )}
                {modalType === 2 && (
                    <SuccessPopup
                        setModal={setOpenModal}
                        successMsg={'Thêm đơn thành công'}
                    />
                )}
            </Modal>
        </div>
    );
}

export default FindOrder;
